import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel } from '@mui/material';


export const SingleCheckbox = ({ fielddata, value, setValue, getValues, errors, clearErrors }) => {
  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, [fielddata]);

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        <FormControlLabel key={fielddata.values.key} control={
          <Checkbox
            checked={getValues(fielddata.name) ? true : false}
            onChange={(e) => {
              //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
              if (e.target.checked === true) {
                setValue(fielddata.name, true);
              } else {
                setValue(fielddata.name, false);
              }
              clearErrors(fielddata.name);
            }}
          />
        } label={fielddata.values.val} />
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
