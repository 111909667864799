import React, { useEffect, useState } from 'react'
import { Cookies, useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { timeConverter } from '../../helper/helperFunctions';
import store from '../../store';

import './thankyou.css';



export const ThankyouPage = () => {
    const [calendarData, setcalendarData] = useState({});
    const [cookieSlotData, setCookieSlotData] = useCookies(["SlotData"]);
    // const [cookieCancelData, setCookieCancelData] = useCookies(["cancelData"]);
    const { page } = useParams();
    const abbrs = {
        HST: 'Hawaii Standard Time',
        EDT: 'Eastern Daylight Time',
        CST: 'Central Standard Time',
        CDT: 'Central Daylight Time',
        MST: 'Mountain Standard Time',
        MDT: 'Mountain Daylight Time',
        PST: 'Pacific Standard Time',
        PDT: 'Pacific Daylight Time',
        IST: 'India Standard Time',
        AKDT: 'Alaska Standard Time'
    };

    store.subscribe(() => {
        const storeData = store.getState();
    })
    useEffect(() => {
        console.log("clickableData+++ 666", calendarData, cookieSlotData);

        if (calendarData && cookieSlotData && cookieSlotData.SlotData && Object.keys(calendarData).length === 0 && Object.keys(cookieSlotData.SlotData).length > 0) {
            setcalendarData(cookieSlotData.SlotData);
        }
        // if (Object.keys(calendarData).length === 0 && Object.keys(cookieCancelData.cancelData).length > 0) {
        //     setcalendarData(cookieSlotData.cancelData);
        // }
    })

    //#########################################################################



    // console.log("page 666", page)

    return (
        <>

            <div className={'darktheme'}>
                <div className='thankyou-container'>
                    <div className='thankyou-block'>
                        <div className='content'>
                            {/* <h1>Your Slot {page === "booking" ? 'Booked' : page === "reschedule" ? "Re-Scheduled" : "Canceled"} Successfully!</h1> */}
                            <h1>{page === "booking" ?
                                "Thank you for scheduling a meeting to discuss our government releif and expense reduction programs for your business contacts and executives.":
                                page === "reschedule" ? 
                                "Thank you for re-scheduling a meeting to discuss our government releif and expense reduction programs for your business contacts and executives." :
                                "Your appointment cancelled successfully" }</h1>
                            {/* <p>User Name : {calendarData.username}</p> */}
                            <p> Event Title : {calendarData.event_title} </p>
                            <p>Organizer E-mail : {calendarData.bookingUserEmail} </p>
                            <p>Slot Booked Date : {calendarData.startdate} </p>
                            <p>Slot Start : {timeConverter({ time: new Date(calendarData.startDateTime), format: "h:mm:ss A", timezone: calendarData.user_timezone })}</p>
                            <p>Slot End :  {timeConverter({ time: new Date(calendarData.endDateTime), format: "h:mm:ss A", timezone: calendarData.user_timezone })}</p>
                            <p>Slot Duration : {calendarData.timespan} mins</p>
                            <p>Timezone : {abbrs[timeConverter({ time: new Date(calendarData.endDateTime), format: "zz", timezone: calendarData.user_timezone })]}</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

