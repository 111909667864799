import React, { useEffect } from 'react'
import { InputLabel, TextField, Icon, FormControl } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';


export const Text = ({ fielddata, value, setValue, getValues, errors, clearErrors }) => {


  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, [fielddata])



  var filter = [];
  const keypadZero = 48;
  const numpadZero = 96;

  //add key codes for digits 0 - 9 into this filter
  for (var i = 0; i <= 9; i++) {
    filter.push(i + keypadZero);
    filter.push(i + numpadZero);
  }

  //add other keys that might be needed for navigation
  //or for editing the keyboard input
  filter.push(8);     //backspace
  filter.push(9);     //tab
  filter.push(46);    //delete
  filter.push(37);    //left arrow
  filter.push(39);    //right arrow

  /*******************************************************
    * onKeyDown(e)
    * when a key is pressed down, check if it is allowed
    * or not. If not allowed, prevent the key event
    * from propagating further
  *******************************************************/
  function onKeyDown(e) {
    if (filter.indexOf(e.keyCode) < 0) {
      e.preventDefault();
      return false;
    }
  }
  // Formating Input text
  function formatPhoneText(value) {
    value = value.trim().replaceAll("-", "");
    value = value.trim().replaceAll("(", "");
    value = value.trim().replaceAll(")", "");
    value = value.trim().replaceAll(" ", "");

    if (value.length > 3 && value.length <= 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3);
    else if (value.length > 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3, 6) + "-" + value.slice(6);

    return value;
  }

  function validatePhone(p) {
    var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
    var digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
  }
  function onKeyUp(e) {
    var input = e.target;
    var formatted = formatPhoneText(input.value);
    var isError = (validatePhone(formatted) || formatted.length === 0);
    var color = (isError) ? "gray" : "red";
    var borderWidth = (isError) ? "1px" : "3px";
    input.style.borderColor = color;
    input.style.borderWidth = borderWidth;
    input.value = formatted;
  }

  function setupPhoneFields(fielddata, value, className) {
    var input = document.querySelectorAll(".phoneField input")[0];
    input.addEventListener("keydown", onKeyDown);
    input.addEventListener("keyup", onKeyUp);
    console.log("valueLength input", value)

    setValue(fielddata.name, value);
  }

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        <TextField
          type={(fielddata.inputType !== undefined || fielddata.inputType !== "") ? fielddata.inputType : "text"}
          value={getValues(fielddata.name) ? getValues(fielddata.name) : ""}
          className={fielddata.inputType === "phone" ? 'phoneField' : ''}
          onChange={(e) => {
            if (fielddata.inputType === "number") {
              setValue(fielddata.name, Number(e.target.value));
            } else if (fielddata.inputType === "phone") {
              setValue(fielddata.name, e.target.value);
              setupPhoneFields(fielddata, e.target.value, 'phoneField');
            } else {
              setValue(fielddata.name, e.target.value);
            }

            clearErrors(fielddata.name); // Clearing errors on focus or change
          }}
          label={fielddata.label}
          error={
            Object.keys(errors).length > 0 && errors[fielddata.name] != null
          }
          helperText={errors[fielddata.name] && errors[fielddata.name].type ? fielddata.errorMessage[errors[fielddata.name].type] : ""}
          InputProps={fielddata.adornments !== undefined ? (fielddata.adornments.position === "start" ? {
            startAdornment: (
              <InputAdornment position={fielddata.adornments.position}>
                <div dangerouslySetInnerHTML={{ __html: fielddata.adornments.content }}></div>
              </InputAdornment>
            ),
          } : {
            endAdornment: (
              <InputAdornment position={fielddata.adornments.position}>
                <div dangerouslySetInnerHTML={{ __html: fielddata.adornments.content }}></div>
              </InputAdornment>
            ),
          }
          ) : {}
          }
        />
      </FormControl>

    </div>
  )
}