import React from "react";

export const FileUploader = ({ fielddata, value, setValue, getValues, errors, clearErrors }) => {

    const fileUploaderHandler = (event)=>{
        console.log(event.target.files);
        setValue(fielddata.name,event.target.files);
    }

    return <div>
        <input type="file" onChange={fileUploaderHandler}/>
    </div>
}