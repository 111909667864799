import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect } from 'react'

export const SelectField = ({ fielddata, value, setValue, getValues, errors, clearErrors }) => {
  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue) {
      if (fielddata.multiple !== undefined && fielddata.multiple === true) setValue(fielddata.name, [fielddata.defaultValue]);
      else setValue(fielddata.name, fielddata.defaultValue);
    }

  }, [fielddata]);

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl
        sx={fielddata.sx}
      >
        {/* Label */}
        {fielddata.label && <InputLabel >{fielddata.label}</InputLabel>}
        <Select
          label={fielddata.label}
          multiple={fielddata.multiple !== undefined && typeof (fielddata.multiple) === "boolean" ? fielddata.multiple : false}
          value={getValues(fielddata.name) ? getValues(fielddata.name) : (fielddata.multiple === true ? [] : '')}
          onChange={(e) => {
            setValue(fielddata.name, e.target.value);
            clearErrors(fielddata.name); // Clearing errors on focus or change
          }}
          displayEmpty
        >
          {fielddata.values.map((value, index) => <MenuItem key={index} value={value.val}>{value.name}</MenuItem>)}
        </Select>
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
