import React, { useEffect, useState } from 'react'
import { FormHelperText, InputLabel } from '@mui/material'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { ContentState, convertFromHTML, convertFromRaw } from 'draft-js';
import { EditorState } from 'draft-js';
import store from '../../store';

// const content = { "entityMap": {}, "blocks": [{ "key": "637gr", "text": "Initialized from content state.", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }] };

export const WYSIWYGeditor = ({ fielddata, value, setValue, getValues, errors, clearErrors, formId }) => {

    console.log("RE render }}}}}}}}}}}}}}}}}]]")
    const [tempDefaultData, setTempDefaultValue] = useState("<p>TEST</p>");
    ////////////////////////////// Initializion of data from store ////////////////////////////
    // const tempDefaultData = store.getState().formSlice.formFieldsData[formId].fields.find(cur => cur.id === fielddata.id).defaultValue;
    // const contentHTML = convertFromHTML(tempDefaultData);
    // const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
    // const content = EditorState.createWithContent(state);


    // const editorRef = useRef();
    // const dispatch = useDispatch();

    // const [data, setData] = useState(content);
    // const [localState, setLocalState] = useState("");

    // store.subscribe(() => {
    //     const storeData = store.getState();
    //     const temp = storeData.formSlice.formFieldsData[formId].fields.find(cur => cur.id === fielddata.id);
    //     const contentHTML = convertFromHTML(temp.defaultValue);
    //     const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
    //     const content = EditorState.createWithContent(state);
    //     setData(content);
    // })


    return (
        <div className={fielddata.className}>
            {/* HEADING */}
            {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
            {/* FIELD */}
            <Editor
                // initialContentState={field}
                editorState={EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(tempDefaultData).contentBlocks))}
                // editorState={getValues(fielddata.name) ? EditorState.createWithContent(convertFromRaw(getValues(fielddata.name))) :""}
                toolbarClassName={fielddata.toolbarClassName}
                wrapperClassName={fielddata.wrapperClassName}
                editorClassName={fielddata.editorClassName}
                wrapperStyle={fielddata.wrapperStyle}
                editorStyle={fielddata.editorStyle}
                toolbarStyle={fielddata.toolbarStyle}

            // onBlur={(e) => {
            //     console.log(e);
            //     setData(convertToEditorState(stateToHTML(convertFromRaw(e))));
            // }}

            // onEditorStateChange={(event) => {
            //     console.log("editor", event, event.getCurrentContent(), 'html ', stateToHTML(event.getCurrentContent()), typeof (stateToHTML(event.getCurrentContent())));
            //     // setValue(fielddata.name, stateToHTML(event.getCurrentContent()))
            //     // setData(event);

            // }}
            // onContentStateChange={(e) => {
            //     console.log("content", e, convertFromRaw(e), stateToHTML(convertFromRaw(e)));
            //     // setData(convertToEditorState(stateToHTML(convertFromRaw(e))));
            //     // setValue(fielddata.name, e);
            //     // clearErrors(fielddata.name)
            // }}
            />
            {/* ERROR */}
            {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
        </div>
    )
}

function convertToEditorState(html) {
    const contentHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
    const content = EditorState.createWithContent(state);
    return content;
}

//" Can't call setState on a component that is not yet mounted. This is a no-op, but it might indicate a bug in your application. Instead, assign to `this.state` directly or define a `state = {};` class property with the desired state in the r componen"t ------ If this error occurs just remove React.StrictMode as package has some problem with Strict Mode

// onContentStateChange creates content object which is easy to store in DB as JSON.({ "entityMap": {}, "blocks": [{ "key": "637gr", "text": "Initialized from content state.", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }] })

// convertFromRaw(contet_object) can be used to convert that object to Content State

// stateToHtml( convertFromRaw(contet_object) ) can be used to convert the Content State to HTML string

// To convert a Content State to EditorState we can use EditorState.craeteWithContent(convertFromRaw(content)), where content = { "entityMap": {}, "blocks": [{ "key": "637gr", "text": "Initialized from content state.", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }] }
