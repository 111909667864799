import React, { useRef, useState } from 'react';
import { ThemeProvider } from '@mui/styles';
import MUIRichTextEditor from 'mui-rte';
import { createTheme } from '@mui/material/styles';
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { FormHelperText, InputLabel } from '@mui/material';
import { stateToHTML } from 'draft-js-export-html';
import { useDispatch } from 'react-redux';
import { setEditorData, updateFormFieldsData } from '../formReducer';
import store from '../../store';




export const MUIRichTexteditor = ({ fielddata, setValue, getValues, errors, clearErrors, formId, setError }) => {
    const [tempDefaultData, setTempDefaultValue] = useState("<p></p>");
    const editorRef = useRef();
    const dispatch = useDispatch();

    store.subscribe(() => {
        const storeData = store.getState();
        if (storeData.formSlice.formFieldsData[formId].fields.find(cur => cur.id === fielddata.id).defaultValue !== undefined) {
            setTempDefaultValue(storeData.formSlice.formFieldsData[formId].fields.find(cur => cur.id === fielddata.id).defaultValue);
        }

        if (storeData.formSlice?.editorData?.userForm !== undefined && storeData.formSlice?.editorData?.userForm[fielddata.id] !== undefined) {
            const updatedData = storeData.formSlice?.editorData?.userForm[fielddata.id];
            setTempDefaultValue(updatedData);
        }
    })

    const myTheme = createTheme({
        // Set up your custom MUI theme here
    })

    const save = (data) => {
        console.log('save mui called ', data);
        let temp = JSON.parse(data)
        console.log(temp, 'tmp')
        temp = convertFromRaw(temp);
        console.log(temp, 'tmp -- ss')
        console.log(stateToHTML(temp), 'tmp html .... ');

        dispatch(setEditorData({ tempVar: stateToHTML(temp), formId, fieldId: fielddata.id }));
        setValue(fielddata.name, stateToHTML(temp));

    }



    return (
        <div>
            {/* HEADING */}
            {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
            {/* FIELD */}

            <ThemeProvider theme={myTheme}  >
                <MUIRichTextEditor
                    ////////////////////////// Get and set Default value into editor ///////////////
                    defaultValue={JSON.stringify(convertToRaw(ContentState.createFromBlockArray(convertFromHTML(tempDefaultData).contentBlocks)))}
                    ref={editorRef}
                    label={fielddata.label}
                    onSave={save}
                    onBlur={() => {
                        editorRef.current?.save();
                    }}
                    inlineToolbar={true}
                    onFocus={() => clearErrors(fielddata.name)}

                />
            </ThemeProvider>
        </div>
    )
}
