import { Autocomplete, CircularProgress, FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';
import { fetchAutoCompleteList } from '../formReducer';
import store from '../../store';

///////////////////// Temp Import /////////////////
import { BASE_URL, reqBody } from '../config';


export const AutoCompleteField = ({ fielddata, value, setValue, getValues, errors, clearErrors }) => {
  const dispatch = useDispatch();

  const [query, setQuery] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  //////////////////////////////////// LOADASH DEBOUNCE ////////////////////////////////////////////////////
  const debouncedFetchData = useCallback(debounce((query) => {
    dispatch(fetchAutoCompleteList({ BASE_URL: fielddata.base_url, reqBody: fielddata.reqBody, query, autoCompleteId: fielddata.name }));
  }, 1000), []);

  //////////////////////////////////////////////// Checking Value ///////////////////////////////////////////
  useEffect(() => {
    if (query !== null && query !== undefined) {
      console.log('From auto complete', BASE_URL, reqBody)
      debouncedFetchData(query);
    }
  }, [query]);

  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);

  }, [fielddata])

  /////////////////////////////////////////////// STORE SUBSCRIPTION FOR REAL TIME SEARCH //////////////////////
  store.subscribe(() => {
    const storeData = store.getState();
    if (storeData.formSlice.loading[fielddata.name] !== undefined) {
      setLoading(storeData.formSlice.loading[fielddata.name]);
    }
    if (storeData.formSlice.autoCompleteList[fielddata.name] !== undefined && storeData.formSlice.autoCompleteList[fielddata.name] !== null) {
      setOptions(storeData.formSlice.autoCompleteList[fielddata.name]);
    }
  })


  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        <Autocomplete
          multiple={fielddata.multiple}
          disablePortal
          value={getValues(fielddata.name) ? getValues(fielddata.name) : ""}
          onChange={(e, newValue) => {
            console.log("newValue", newValue, e.target.value);
            ///////////////// Value set for single selection //////////////
            if (!fielddata.multiple && newValue !== null) setValue(fielddata.name, newValue.label);
            ///////////////// Value set for multiple selection //////////////
            if (fielddata.multiple && newValue !== null) {
              const tempArray = newValue.map(val => val.label);
              setValue(fielddata.name, tempArray);
            }
          }}
          options={options}
          sx={fielddata.sx}
          renderInput={(params) => {
            return <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {/* LOADER ON FETCH OPERATION */}
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              onChange={(e) => {
                setQuery(e.target.value);
                clearErrors(fielddata.name);
              }} label={fielddata.label} />
          }
          }
        />
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
