import React from "react";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import BackendHeader from "../Common/BackendHeader/BackendHeader";
import BackendFooter from "../Common/BackendFooter/BackendFooter";
import "./BackendLayout.css";
import { Cookies, CookiesProvider, useCookies } from "react-cookie";

const BackendLayout = () => {
  const location = useLocation();
  let match = useMatch("/bio-energetics-discovery/:user_id");
  let pecematch = useMatch("/pece-discovery/:user_id");
  let intakepecematch = useMatch("/intake-pece-discovery/:user_id");
  let intakebiomatch = useMatch("/intake-bio-discovery/:user_id");
  let cancelslotmatch = useMatch("/cancel-slot/:identifier");
  let reschedulePath = useMatch("/re-schedule-appointment/:identifier");
  let thankyouPath = useMatch("/thankyou/:page");
  let organizerSlot = useMatch("/calendar-management-booking-slots");


  console.log("pecematch", intakebiomatch);
  console.log("bio match", match);
  var biopathFlag = true;
  var pecepathFlag = true;
  switch (location.pathname) {
    case "/bio-energetics-discovery":
      biopathFlag = false;
      break;
    case "/pece-discovery":
      pecepathFlag = false;
      break;
    case "/bio-energetics-integration":
      pecepathFlag = false;
      break;
    case "/pece-integration":
      pecepathFlag = false;
      break;
    case "/beto-paredes-llc":
      pecepathFlag = false;
      break;

    default:
      break;
  }
  if (cancelslotmatch != undefined && cancelslotmatch != null && cancelslotmatch.pattern.path === '/cancel-slot/:identifier') {
    biopathFlag = false;
  }
  if (reschedulePath !== undefined && reschedulePath !== null && reschedulePath.pattern.path === '/re-schedule-appointment/:identifier') {
    biopathFlag = false;
  }
  if (organizerSlot !== undefined && organizerSlot !== null && organizerSlot.pattern.path === '/calendar-management-booking-slots') {
    // biopathFlag = false;
  }
  if (thankyouPath !== undefined && thankyouPath !== null && thankyouPath.pattern.path === '/thankyou/:page') {
    biopathFlag = false;
  }
  if (match != undefined && match != null && match.pattern.path === '/bio-energetics-discovery/:user_id') {
    biopathFlag = false;
  }
  if (pecematch != undefined && pecematch != null && pecematch.pattern.path === '/pece-discovery/:user_id') {
    pecepathFlag = false;
  }
  if (pecematch != undefined && pecematch != null && pecematch.pattern.path === '/pece-discovery/:user_id') {
    pecepathFlag = false;
  }
  if (intakepecematch != undefined && intakepecematch != null && intakepecematch.pattern.path === '/intake-pece-discovery/:user_id') {
    pecepathFlag = false;
  }
  if (intakebiomatch != undefined && intakebiomatch != null && intakebiomatch.pattern.path === '/intake-bio-discovery/:user_id') {
    pecepathFlag = false;
    console.log("headerss")
  }
  console.log("location", location);
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  // console.log("getcookies", getcookies);
  var userType = "";
  if (getcookies != undefined && getcookies.userInfo != undefined) {
    userType = getcookies.userInfo.type;

  }
  console.log("cookies in layout==>", userType);

  if (location.pathname === '/' || location.pathname === '/login' || location.pathname === '/forgot-password') {
    document.body.className += ' noPadding';
  }

  return (
    <>
      <div className="mainContent p30TB">

        {(userType === 'admin' && biopathFlag == true && pecepathFlag == true) &&
          <BackendHeader />
        }

        <div className="pageMain">
          <Outlet />
        </div>

        {(userType === 'admin') &&
          <BackendFooter />
        }


      </div>


    </>
  );
};

export default BackendLayout;
