import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchCalenderEvents = createAsyncThunk("calendar/availability", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_CALENDER_URL + "calender-management-api/view-event-eventdayarr",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const fetchCalenderIntegrationEvents = createAsyncThunk("calendar/availabilityintegration", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/view-event-eventdayarr-integration",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const fetchCalenderIntegrationEventsclockmore = createAsyncThunk("calendar/availabilityintegrationclickmore", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/view-event-eventdayarr-integration",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});

export const fetchCalenderAgainIntegrationEvents = createAsyncThunk("calendar/availabilityintegrationagain", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/view-event-eventdayarr-integration",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const fetchCalenderPECEIntegrationEvents = createAsyncThunk("calendar/availabilitypeceintegration", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/view-event-eventdayarr-pece-integration",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const fetchCalenderPECEIntegrationEventsmore = createAsyncThunk("calendar/availabilitypeceintegrationmore", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/view-event-eventdayarr-pece-integration",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});

export const fetchCalenderEventsLoadMore = createAsyncThunk("calendar/availabilityloadmore", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_CALENDER_URL + "calender-management-api/view-event-eventdayarr",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const fetchCalenderEventsLoadMorenoconcat = createAsyncThunk("calendar/availabilityloadmorenoconcat", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_CALENDER_URL + "calender-management-api/view-event-eventdayarr",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});

export const fetchBookedSlotData = createAsyncThunk("calendar/bookedSlotData", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_CALENDER_URL + "api/booked-slot-data",
    // "http://localhost:3000/dev/api/booked-slot-data",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});

export const updateGoogleTokenGmail = createAsyncThunk("calendar/updateGoogleTokenGmail", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "updatetokengmail",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});

export const booknowevents = createAsyncThunk("calendar/booknowevents", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/add-calendar",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});

// for event listing
export const fetchEventsData = createAsyncThunk("calendar/eventlist", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + 'api/availability-list',
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const fetchEventWithIdentifier = createAsyncThunk("fetch-event-identifier", async (requestBody) => {
  const reqBody = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(requestBody)
  }
  const res = await fetch(process.env.REACT_APP_API_URL + "api/fetch-event-identifier", reqBody);
  const respData = await res.json();
  return respData
})
export const rescheduleevents = createAsyncThunk("calendar/rescheduleevent", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/re-schedule-event", requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const cancelEvent = createAsyncThunk("cancel-event", async (requestBody) => {
  const reqBody = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(requestBody),
  }
  console.log("request body", requestBody)
  const res = await fetch(process.env.REACT_APP_GOOGLE_CALENDER_URL + "calendar-event/cancel-event", reqBody);
  const respData = await res.json();

  return respData;
});

export const fetchOrganizerEvents = createAsyncThunk("calendar/fetch-google-events", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_CALENDER_URL + "calender-management-api/fetch-google-events",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});
export const fetchOrganizerEventsLoadMore = createAsyncThunk("calendar/fetch-google-events-loadmore", async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_CALENDER_URL + "calender-management-api/fetch-google-events",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});

const calenderEvents = createSlice({
  name: "calender",
  initialState: {
    calenderEventsData: [],
    calenderEventsAgainData: [],
    eventsDataSet: [],
    syncGoogleAcountData: {},
    booknowData: {},
    bookNowSubmissionState: 0,
    loading: false,
    booknowMsg: "",
    bookedSlotData: {},
    fetchIdentifierData: {},
    rescheduleData: '',
    rescheduleMsg: '',
    rescheduleSubmissionState: 0,
    successMsg: '',
    error: false,
    errorMsg: '',
    cancelSubmissionState: 0,
  },
  reducers: {
    setBooknowSubmissionState(state, action) {
      state.bookNowSubmissionState = 0;
    },
    setrescheduleSubmissionStateReset(state, action) {
      state.success = false;
      state.rescheduleMsg = '';
      state.rescheduleSubmissionState = 0;
    },
    cancelSubmissionStateReset(state, action) {
      state.success = false;
      state.successMsg = '';
      state.cancelSubmissionState = 0;
    }
  },
  extraReducers: {
    /////////////////// Fetch Booked Slot Data for Cancel //////////////
    [fetchBookedSlotData.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchBookedSlotData.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchBookedSlotData.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data", action.payload.data);
        state.bookedSlotData = action.payload.results[0];
      }
    },
    // fetchCalenderIntegrationEvents

    [fetchCalenderEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderEvents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data7/7/7//", action.payload.data);
        state.calenderEventsData = [...action.payload.data];
      }

    },
    [fetchCalenderIntegrationEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderIntegrationEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderIntegrationEvents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data999999", action.payload.data);
        state.calenderEventsData = [...action.payload.data];
      }
    },
    [fetchCalenderIntegrationEventsclockmore.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderIntegrationEventsclockmore.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderIntegrationEventsclockmore.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data999999", action.payload.data);
        state.calenderEventsData = [...state.calenderEventsData, ...action.payload.data];
      }
    },


    [fetchCalenderAgainIntegrationEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderAgainIntegrationEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderAgainIntegrationEvents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data", action.payload.data);
        state.calenderEventsAgainData = [...action.payload.data];
      }
    },

    [fetchCalenderPECEIntegrationEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderPECEIntegrationEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderPECEIntegrationEvents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data", action.payload.data);
        state.calenderEventsData = [...action.payload.data];
      }
    },
    [fetchCalenderPECEIntegrationEventsmore.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderPECEIntegrationEventsmore.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderPECEIntegrationEventsmore.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data", action.payload.data);
        state.calenderEventsData = [...state.calenderEventsData, ...action.payload.data];
      }
    },




    [fetchCalenderEventsLoadMore.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderEventsLoadMore.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderEventsLoadMore.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data", action.payload.data);
        state.calenderEventsData = [...state.calenderEventsData, ...action.payload.data];
      }
    },

    [fetchCalenderEventsLoadMorenoconcat.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderEventsLoadMorenoconcat.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderEventsLoadMorenoconcat.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data", action.payload.data);
        state.calenderEventsData = [...action.payload.data];
      }
    },









    [fetchOrganizerEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchOrganizerEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchOrganizerEvents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data", action.payload.data);
        state.calenderEventsData = [...action.payload.data];
      }
    },
    [fetchOrganizerEventsLoadMore.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchOrganizerEventsLoadMore.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchOrganizerEventsLoadMore.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("action.payload.data", action.payload.data);
        state.calenderEventsData = [...state.calenderEventsData, ...action.payload.data];
      }
    },
    // event list start
    [fetchEventsData.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchEventsData.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchEventsData.fulfilled]: (state, action) => {
      console.log("fetchEventsData.fulfilled called");
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
        console.log("in success part fetchEventsData", action.payload);

        state.eventsDataSet = action.payload.results.res;
      }
    },
    // evevnt list end here
    [updateGoogleTokenGmail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateGoogleTokenGmail.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateGoogleTokenGmail.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      // if (action.payload.status === "success") {
      console.log("+++++++++++", action.payload);
      state.syncGoogleAcountData = action.payload.data;
      state.connectedgmail = action.payload.connectedgmailforce;
      state.gooleconnecttime = action.payload.gooleconnecttime;
      // }
    },
    [booknowevents.pending]: (state, action) => {
      state.loading = true;
      state.booknowData = "pending";
      state.booknowMsg = "";
      state.bookNowSubmissionState = 1;

    },
    [booknowevents.rejected]: (state, action) => {
      state.loading = false;
      state.booknowData = "rejected";
      state.booknowMsg = "";
      state.bookNowSubmissionState = 3;
    },
    [booknowevents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      // if (action.payload.status === "success") {
      console.log("+++++++++++", action.payload);
      state.booknowData = "success";
      state.booknowMsg = action.payload.status;
      if (state.booknowMsg == "Booking done") {
        state.bookNowSubmissionState = 2;
      }


      // }
    },
    [fetchEventWithIdentifier.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchEventWithIdentifier.fulfilled]: (state, action) => {
      console.log(action)
      if (action.payload.status === 'success') {
        state.loading = false;
        state.success = true;
        state.fetchIdentifierData = action.payload.res;
      }
    },
    [fetchEventWithIdentifier.rejected]: (state, action) => {
      console.log(action)
      state.loading = false;
      state.error = action.payload
    },
    [rescheduleevents.pending]: (state, action) => {
      state.loading = true;
      state.rescheduleData = "pending";
      state.rescheduleMsg = "";
      state.rescheduleSubmissionState = 1;

    },
    [rescheduleevents.rejected]: (state, action) => {
      state.loading = false;
      state.rescheduleData = "rejected";
      state.rescheduleMsg = "";
      state.rescheduleSubmissionState = 3;
    },
    [rescheduleevents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      // if (action.payload.status === "success") {
      console.log("+++++++++++", action.payload);
      state.rescheduleData = "success";
      state.rescheduleMsg = action.payload.status;
      state.rescheduleSubmissionState = 2;
    },
    [cancelEvent.pending]: (state, action) => {
      state.loading = true;
      state.cancelSubmissionState = 1;
    },
    [cancelEvent.fulfilled]: (state, action) => {
      console.log(action)
      if (action.payload.status === "Event Cancelled" || action.payload.status === "Failed to Cancel event") {
        state.loading = false;
        state.success = true;
        state.successMsg = action.payload.status;
        if (action.payload.status !== "Failed to Cancel event") {
          state.cancelSubmissionState = 2;
        }
      }
    },
    [cancelEvent.rejected]: (state, action) => {
      console.log("action 888", action)
      state.loading = false;
      if (action.error) {
        state.error = true;
        state.errorMsg = action.error.message;
        state.cancelSubmissionState = 3;
      }
    },

  },
});

export const { setBooknowSubmissionState, setrescheduleSubmissionStateReset, cancelSubmissionStateReset } = calenderEvents.actions;


export default calenderEvents.reducer;
