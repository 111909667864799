import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import React, { useEffect } from 'react'
import { FormHelperText, InputLabel } from '@mui/material';

export const RadioSelection = ({ fielddata, value, setValue, getValues, errors, clearErrors }) => {

  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);

  }, [fielddata])

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        <RadioGroup
          value={getValues(fielddata.name) ? getValues(fielddata.name) : ""}
          onChange={(e) => {
            setValue(fielddata.name, e.target.value);
            clearErrors(fielddata.name); // Clearing errors on focus or change
          }}
        >
          {fielddata.values.map(eachVal => <FormControlLabel
            key={eachVal.key}
            value={eachVal.val}
            control={<Radio />}
            label={eachVal.val}
          />)}
        </RadioGroup>
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
