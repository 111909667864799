import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const getEventSlotsNotCreated = createAsyncThunk("getEventSlotsNotCreated/list-count", async ({ body = {}, path = "", baseUrl = "" }) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  const response = await fetch(baseUrl + path, requestOptions);
  const respdata = await response.json();
  return respdata;
});

export const getEventDayArrNotCreated = createAsyncThunk("getEventDayArrNotCreated/list-count", async ({ body = {}, path = "", baseUrl = "" }) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  const response = await fetch(baseUrl + path, requestOptions);
  const respdata = await response.json();
  return respdata;
});

export const getEventDaySlotsEmpty = createAsyncThunk("getEventDaySlotsEmpty/list-count", async ({ body = {}, path = "", baseUrl = "" }) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  const response = await fetch(baseUrl + path, requestOptions);
  const respdata = await response.json();
  return respdata;
});

const developerInfoSlice = createSlice({
  name: 'developerInfoSlice',
  initialState: {
    eventDayArrNotCreated: null,
    eventDayArrNotCreatedLoading: false,
    eventSlotsNotCreated: null,
    eventSlotsCreatedLoading: false,
    eventSlotsEmpty: null,
    eventSlotsEmptyLoading: false,
  },
  reducers: {
    resetStates(state, action) {
      state.eventDayArrNotCreated = null;
      state.eventDayArrNotCreatedLoading = false;
      state.eventSlotsNotCreated = null;
      state.eventSlotsCreatedLoading = false;
      state.eventSlotsEmpty = null;
      state.eventSlotsEmptyLoading = false;
    }
  },
  extraReducers: {
    [getEventSlotsNotCreated.pending]: (state, action) => {
      state.eventSlotsNotCreated = null
      state.eventSlotsCreatedLoading = true
    },
    [getEventSlotsNotCreated.rejected]: (state, action) => {
      state.eventSlotsCreatedLoading = false
    },
    [getEventSlotsNotCreated.fulfilled]: (state, action) => {
      state.eventSlotsCreatedLoading = false
      if (action.payload.status === 'success') state.eventSlotsNotCreated = action.payload.results.res
      else state.eventSlotsNotCreated = []
    },

    [getEventDayArrNotCreated.pending]: (state, action) => {
      state.eventDayArrNotCreated = null
      state.eventDayArrNotCreatedLoading = true
    },
    [getEventDayArrNotCreated.rejected]: (state, action) => {
      state.eventDayArrNotCreatedLoading = false

    },
    [getEventDayArrNotCreated.fulfilled]: (state, action) => {
      state.eventDayArrNotCreatedLoading = false
      if (action.payload.status === 'success') state.eventDayArrNotCreated = action.payload.results.res
      else state.eventDayArrNotCreated = []
    },

    [getEventDaySlotsEmpty.pending]: (state, action) => {
      state.eventSlotsEmpty = null
      state.eventSlotsEmptyLoading = true
    },
    [getEventDaySlotsEmpty.rejected]: (state, action) => {
      state.eventSlotsEmptyLoading = false

    },
    [getEventDaySlotsEmpty.fulfilled]: (state, action) => {
      state.eventSlotsEmptyLoading = false
      if (action.payload.status === 'success') state.eventSlotsEmpty = action.payload.results.res
      else state.eventSlotsEmpty = []
    },
  }
})

export default developerInfoSlice.reducer;
export const { resetStates } = developerInfoSlice.actions;