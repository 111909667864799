import { configureStore } from "@reduxjs/toolkit";
import loginUser from "./Components/Backend/Pages/Login/Login_reducer";
import forgetPasswordUser from "./Components/Backend/Pages/ForgetPassword/ForgetPassword_reduces";
import formSlice from "./form/formReducer";
import tableSlice from "./listing/listReducer";
import calenderEvents from "./Components/Backend/Pages/calendarModule/calendarReducer";
import middlewareDataReducer from './Components/Backend/Pages/middleware/middlewareReducer'
import searchReducer from "./search/searchReducer";
import developerInfoSlice from './Components/Backend/Pages/Developer Info/developerInfoReducer'

export default configureStore({
    reducer: {
        loginUserKey: loginUser,
        forgetPasswordUserKey: forgetPasswordUser,
        formSlice: formSlice,
        tableSlice: tableSlice,
        calenderEventsStore: calenderEvents,
        middlewareDatareducer: middlewareDataReducer,
        searchSlice: searchReducer,
        developerInfoSlice: developerInfoSlice
    },
});