import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel } from '@mui/material'
import React, { useEffect } from 'react'

export const MultipleCheckBox = ({ fielddata, value, setValue, getValues, errors, clearErrors }) => {
  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, [fielddata]);

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        {
          fielddata.values.map(eachVal => <FormControlLabel key={eachVal.key} control={
            <Checkbox
              checked={getValues(fielddata.name) && getValues(fielddata.name).includes(eachVal.val) ? true : false}
              onChange={(e) => {
                //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                let tempCheckboxValue = getValues(fielddata.name);
                if (tempCheckboxValue === undefined) tempCheckboxValue = [];
                if (e.target.checked === true) {
                  tempCheckboxValue = [...tempCheckboxValue];// Make a copy of array or error will occured
                  tempCheckboxValue.push(eachVal.val);
                } else {
                  tempCheckboxValue = [...tempCheckboxValue];// Make a copy of array or error will occured
                  tempCheckboxValue.splice(tempCheckboxValue.indexOf(eachVal.key), 1);
                }
                setValue(fielddata.name, tempCheckboxValue);
                clearErrors(fielddata.name);
              }}
            />
          } label={eachVal.val} />)
        }
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
