import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Button } from "@mui/material";
import { Form } from "../../../../form/Form";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../../store";
import { resetForm, resetFormResetFlag } from "../../../../form/formReducer";
import { fetchTableCount, fetchTableList, setReloadTableFlag } from "../../../../listing/listReducer";

export const ManageCronForm = () => {

  const [accordion, setAcccordion] = useState(false);
  const loginUserId = useSelector((state) => state.loginUserKey.userInfo._id);
  const [formSubmissionState, setFormSubmissionState] = useState(0);
  const [expanded, setExpanded] = useState('');
  const dispatch = useDispatch();


  const formData = {
    id: "cronCreateForm",
    api_url: process.env.REACT_APP_PECE_DEV_URL,
    endPoint: "calendarapi/addremove-cron-manager",
    // urlPathOnSuccessfulFormSubmission: "/calendar-management-manageUser",
    formButtonClass: "submitbtnsection",
    // Body: {
    //   operation: "add or delete",
    //   position: 2, // Must be a number// not needed in delete operation
    //   url: "input", // must be a string
    // },

    fields: [
      //////////////////////////////////// Text Input Event Title ///////////////////////////////
      {
        id: 0,
        label: "Url",
        name: "url",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue:
        //   editData && Object.keys(editData).length > 0
        //     ? editData.firstname
        //     : "",
      },

      {
        id: 1,
        label: "Position",
        name: "position",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
      },
      // {
      //   id: 2,
      //   label: "Phone",
      //   name: "phone",
      //   className: "formGroup eventTitle countDiv__column--col6",
      //   type: "text",
      //   inputType: 'phone',
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //   },
      // }
    ],
    formAdditionalSubmissionData: {
      //   created_by: `${loginUserId}`,
      //   created_time: new Date().getTime(),
      operation: 'add'
    },
  };

  store.subscribe(() => {
    const storeData = store.getState();
    // console.log("storeData", storeData)
    // if(storeData.formSlice.formSubmissionState && storeData.formSlice.formSubmissionState.cronCreateForm){

    setFormSubmissionState(
      storeData.formSlice.formSubmissionState.cronCreateForm
    );
    // }
  })
  useEffect(() => {
    /////////////////// 1 for pending form submission //////////////////

    //////////////////// 2 for successful form submission //////////////////
    if (formSubmissionState === 2) {

      console.log("<==============newFormData===============>", formData);
      dispatch(resetForm({ formId: "cronCreateForm" }));
      dispatch(setReloadTableFlag({tableId:"manageCronDevListing"}));
      setExpanded('');
    }
  }, [formSubmissionState]);

  // console.log("<==============newFormData===============>", formData);

  const handleChange = (panel) => (event, isAccordion) => {
    console.log("panel====>", panel);
    // setAcccordion(isAccordion ? panel : false);
    setExpanded(isAccordion ? panel : false)
  };

  return (
    <>
      <div>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
          <AccordionSummary className="accordion_summary">
            <Button variant="contained">+ Add Cron</Button>
          </AccordionSummary>
          <AccordionDetails>
            <Form formData={formData} />
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
