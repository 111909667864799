import { FormControl, FormHelperText, FormLabel, InputLabel, TextareaAutosize } from '@mui/material'
import React, { useEffect } from 'react'

export const TextArea = ({ fielddata, value, setValue, getValues, errors, clearErrors }) => {

  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, [fielddata])

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        {fielddata.label && <FormLabel >{fielddata.label}</FormLabel>}
        <TextareaAutosize
          minRows={fielddata.rows}
          style={fielddata.style}
          value={getValues(fielddata.name) ? getValues(fielddata.name) : ""}
          onChange={(e) => {
            setValue(fielddata.name, e.target.value);
            clearErrors(fielddata.name); // Clearing errors on focus or change
          }}
        />
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
