import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import EventDayArrNotCreated from '../../Common/EventDayArrNotCreated'
import EventDayEmptySlots from '../../Common/EventDayEmptySlots';
import EventSlotsNotCreated from '../../Common/EventSLotsNotCreated';
import { resetStates } from '../../developerInfoReducer'

function PeceLive() {
  const dispatch = useDispatch();

  /////////////////////////// Event Day Arr Not Created ///////////////////////////////////////////////////
  const eventDayArrNotCreatedtableConfig = {
    tableId: "eventDayArrNotCreatedLive",
    tableTitle: "PECE Live Calendar Eventdayarr Not Created",
    showReload: true,
    // showFilter: true,
    api: {
      url: process.env.REACT_APP_PECE_PROD_URL,
      // deleteUrl: process.env.REACT_APP_PECE_PROD_URL,
      endPoint: "calendarapi/eventdayarr-not-created",
      tableCountEndpoint: "calendarapi/eventdayarr-not-created",
      // deleteSingleUserEndpoint: "delete-event",
    },
    reqBody: {
      source: "",
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "created_at",
      },
      searchcondition: {},
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
      secret: "JwX.(1QHj",
    },
    // deleteSingleUserReqBody: {
    //   token:
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE",
    // },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: [],
    // paginationType: "stackPagination",
    customBtnHead: [
      // {
      //   id: "deleteMultiple",
      //   type: "delete",
      //   title: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "editMultiple",
      //   type: "toggle",
      //   title: "Status Change",
      //   name: 'toggle'
      // }
    ],
    buttons: [
      // {
      //   id: "delete",
      //   type: "delete",
      //   label: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "edit",
      //   type: "edit",
      //   label: "Edit",
      //   name: "edit",
      // },
      // {
      //   id: "view",
      //   type: "view",
      //   label: "View",
      //   name: "view",
      // },
    ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      },
    },
    // searchBarData: {
    //   heading: "This is Search Bar",
    //   api: {
    //     url: "http://localhost:3000/dev/",
    //     endPoint: "api/listingusers",
    //     reqBody: {
    //       source: "",
    //       condition: {
    //         limit: 10,
    //         skip: 0,
    //       },
    //       sort: {
    //         type: "asc",
    //         field: "firstname",
    //       },
    //     },
    //   },
    //   searchSettings: {
    //     datesearch: [
    //       {
    //         id: 0,
    //         startdatelabel: "Joined On Start Date",
    //         enddatelabel: "Joined On End Date",
    //         submit: "Search",
    //         field: "createdon_datetime",
    //       },
    //     ],
    //     selectsearch: [
    //       {
    //         id: 1,
    //         label: "Search By Status",
    //         field: "status",
    //         values: ["Active", "Inactive"],
    //         value: "",
    //       },
    //     ],
    //     textsearch: [
    //       { id: 2, label: "Search By phone ", field: "phone", value: "" },
    //     ],
    //     autoCompleteSearch: [
    //       {
    //         id: 5,
    //         label: "Search By Name",
    //         field: "fullname_s",
    //         values: "",
    //         multiple: false,
    //         serverSearchData: {
    //           base_url: "http://localhost:3000/dev/",
    //           endpoint: "api6/autocompletemanagername",
    //           reqBody: {
    //             condition: {
    //               limit: 10,
    //               skip: 0,
    //             },
    //             sort: {
    //               type: "desc",
    //               field: "_id",
    //             },
    //             searchcondition: {},
    //           },
    //         },
    //       },
    //     ],
    //   },
    // },
  }

  const eventDayArrNotCreatedHeaders = [
    { val: "event_title", name: "Event Title" },
    { val: "username", name: "Organiser Name" },
    { val: "useremail", name: "Organiser Email" },
    { val: "last_dayarr_checked_on", name: "Last Event Day Arr Checked", type: "datetime", format: "DD/MM/YYYY hh:mm A" },
    { val: "created_at", name: "Creation Time", type: "datetime", format: "DD/MM/YYYY hh:mm A" }
  ]
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////// Event Slots Not Created ///////////////////////////////////////////////////
  const eventSlotsNotCreatedtableConfig = {
    tableId: "eventSlotsNotCreatedDev",
    tableTitle: "PECE Live Calendar Slots Not Created",
    showReload: true,
    // showFilter: true,
    api: {
      url: process.env.REACT_APP_PECE_PROD_URL,
      // deleteUrl: process.env.REACT_APP_PECE_PROD_URL,
      endPoint: "calendarapi/event-slot-not-created",
      tableCountEndpoint: "calendarapi/event-slot-not-created",
      // deleteSingleUserEndpoint: "delete-event",
    },
    reqBody: {
      source: "",
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "created_at",
      },
      searchcondition: {},
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
      secret: "JwX.(1QHj",
    },
    // deleteSingleUserReqBody: {
    //   token:
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE",
    // },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: [],
    // paginationType: "stackPagination",
    customBtnHead: [
      // {
      //   id: "deleteMultiple",
      //   type: "delete",
      //   title: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "editMultiple",
      //   type: "toggle",
      //   title: "Status Change",
      //   name: 'toggle'
      // }
    ],
    buttons: [
      // {
      //   id: "delete",
      //   type: "delete",
      //   label: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "edit",
      //   type: "edit",
      //   label: "Edit",
      //   name: "edit",
      // },
      // {
      //   id: "view",
      //   type: "view",
      //   label: "View",
      //   name: "view",
      // },
    ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      },
    },
    // searchBarData: {
    //   heading: "This is Search Bar",
    //   api: {
    //     url: "http://localhost:3000/dev/",
    //     endPoint: "api/listingusers",
    //     reqBody: {
    //       source: "",
    //       condition: {
    //         limit: 10,
    //         skip: 0,
    //       },
    //       sort: {
    //         type: "asc",
    //         field: "firstname",
    //       },
    //     },
    //   },
    //   searchSettings: {
    //     datesearch: [
    //       {
    //         id: 0,
    //         startdatelabel: "Joined On Start Date",
    //         enddatelabel: "Joined On End Date",
    //         submit: "Search",
    //         field: "createdon_datetime",
    //       },
    //     ],
    //     selectsearch: [
    //       {
    //         id: 1,
    //         label: "Search By Status",
    //         field: "status",
    //         values: ["Active", "Inactive"],
    //         value: "",
    //       },
    //     ],
    //     textsearch: [
    //       { id: 2, label: "Search By phone ", field: "phone", value: "" },
    //     ],
    //     autoCompleteSearch: [
    //       {
    //         id: 5,
    //         label: "Search By Name",
    //         field: "fullname_s",
    //         values: "",
    //         multiple: false,
    //         serverSearchData: {
    //           base_url: "http://localhost:3000/dev/",
    //           endpoint: "api6/autocompletemanagername",
    //           reqBody: {
    //             condition: {
    //               limit: 10,
    //               skip: 0,
    //             },
    //             sort: {
    //               type: "desc",
    //               field: "_id",
    //             },
    //             searchcondition: {},
    //           },
    //         },
    //       },
    //     ],
    //   },
    // },
  }

  const eventSlotsNotCreatedHeaders = [
    { val: "event_title", name: "Event Title" },
    { val: "username", name: "Organiser Name" },
    { val: "useremail", name: "Organiser Email" },
    { val: "last_recreated", name: "Last Recreated On", type: "datetime", format: "DD/MM/YYYY hh:mm A" },
    { val: "created_at", name: "Creation Time", type: "datetime", format: "DD/MM/YYYY hh:mm A" }
  ]
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////// Event Slots Empty ///////////////////////////////////////////////////
  const eventSlotsEmptytableConfig = {
    tableId: "eventSlotsEmptyDev",
    tableTitle: "PECE Live Calendar Slots Empty",
    showReload: true,
    // showFilter: true,
    api: {
      url: process.env.REACT_APP_PECE_PROD_URL,
      // deleteUrl: process.env.REACT_APP_PECE_PROD_URL,
      endPoint: "calendarapi/empty-slots",
      tableCountEndpoint: "calendarapi/empty-slots",
      // deleteSingleUserEndpoint: "delete-event",
    },
    reqBody: {
      source: "",
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        "type": "desc",
        "field": "startdate"
      },
      searchcondition: {},
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
      secret: "JwX.(1QHj",
    },
    // deleteSingleUserReqBody: {
    //   token:
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE",
    // },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: [],
    // paginationType: "stackPagination",
    customBtnHead: [
      // {
      //   id: "deleteMultiple",
      //   type: "delete",
      //   title: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "editMultiple",
      //   type: "toggle",
      //   title: "Status Change",
      //   name: 'toggle'
      // }
    ],
    buttons: [
      // {
      //   id: "delete",
      //   type: "delete",
      //   label: "Delete",
      //   name: "delete",
      // },
      // {
      //   id: "edit",
      //   type: "edit",
      //   label: "Edit",
      //   name: "edit",
      // },
      // {
      //   id: "view",
      //   type: "view",
      //   label: "View",
      //   name: "view",
      // },
    ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      },
    },
    // searchBarData: {
    //   heading: "This is Search Bar",
    //   api: {
    //     url: "http://localhost:3000/dev/",
    //     endPoint: "api/listingusers",
    //     reqBody: {
    //       source: "",
    //       condition: {
    //         limit: 10,
    //         skip: 0,
    //       },
    //       sort: {
    //         type: "asc",
    //         field: "firstname",
    //       },
    //     },
    //   },
    //   searchSettings: {
    //     datesearch: [
    //       {
    //         id: 0,
    //         startdatelabel: "Joined On Start Date",
    //         enddatelabel: "Joined On End Date",
    //         submit: "Search",
    //         field: "createdon_datetime",
    //       },
    //     ],
    //     selectsearch: [
    //       {
    //         id: 1,
    //         label: "Search By Status",
    //         field: "status",
    //         values: ["Active", "Inactive"],
    //         value: "",
    //       },
    //     ],
    //     textsearch: [
    //       { id: 2, label: "Search By phone ", field: "phone", value: "" },
    //     ],
    //     autoCompleteSearch: [
    //       {
    //         id: 5,
    //         label: "Search By Name",
    //         field: "fullname_s",
    //         values: "",
    //         multiple: false,
    //         serverSearchData: {
    //           base_url: "http://localhost:3000/dev/",
    //           endpoint: "api6/autocompletemanagername",
    //           reqBody: {
    //             condition: {
    //               limit: 10,
    //               skip: 0,
    //             },
    //             sort: {
    //               type: "desc",
    //               field: "_id",
    //             },
    //             searchcondition: {},
    //           },
    //         },
    //       },
    //     ],
    //   },
    // },
  }

  const eventSlotsEmptyHeaders = [
    { val: "event_title", name: "Event Title" },
    { val: "username", name: "Organiser Name" },
    { val: "useremail", name: "Organiser Email" },
    { val: "startdate", name: "Date" },
    { val: "created_at", name: "Creation Time", type: "datetime", format: "DD/MM/YYYY hh:mm A" }
  ]
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    return () => {
      dispatch(resetStates())
    }
  }, [])


  return (
    <>
      <div className='reactTableWrapper'>
        <EventDayArrNotCreated tableConfig={eventDayArrNotCreatedtableConfig} tableHeaders={eventDayArrNotCreatedHeaders} />
      </div>
      <div className='reactTableWrapper'>
        <EventSlotsNotCreated tableConfig={eventSlotsNotCreatedtableConfig} tableHeaders={eventSlotsNotCreatedHeaders} />
      </div>
      <div className='reactTableWrapper'>
        <EventDayEmptySlots tableConfig={eventSlotsEmptytableConfig} tableHeaders={eventSlotsEmptyHeaders} />
      </div>
    </>

  )
}

export default PeceLive