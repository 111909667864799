import { Paper } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ListingTable from '../../../../../listing/listing';
import { TableSkeleton } from '../../../../Common/TableSkeleton';
import { getEventSlotsNotCreated } from '../developerInfoReducer';

function EventSlotsNotCreated({ tableConfig, tableHeaders }) {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.developerInfoSlice.eventSlotsNotCreated)
  const loading = useSelector((state) => state.developerInfoSlice.eventSlotsCreatedLoading)


  useEffect(() => {
    dispatch(getEventSlotsNotCreated({ body: tableConfig.reqBody, path: tableConfig.api.endPoint, baseUrl: tableConfig.api.url }))
  }, [])

  return (
    <div className='reactTable'>
      {(!loading && list) ? (
        <ListingTable tableData={tableConfig} dataset={list} modifyHeaders={tableHeaders} />
      ) : (
        <Paper sx={{ background: "#c9d2d9" }}>
          <TableSkeleton count={5} />
        </Paper>
      )}

    </div>
  )
}

export default EventSlotsNotCreated