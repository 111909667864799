import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch } from "react-redux";
import { fetchCalenderEvents, fetchCalenderIntegrationEvents, fetchCalenderPECEIntegrationEvents, fetchCalenderPECEIntegrationEventsmore, updateGoogleTokenGmail, booknowevents, fetchCalenderEventsLoadMore, setBooknowSubmissionState, fetchEventWithIdentifier, rescheduleevents, setrescheduleSubmissionStateReset, fetchCalenderEventsLoadMorenoconcat } from "./calendarReducer";
import "../calendarModule/calender.css";
import store from "../../../../store";
import { Button, Menu, Snackbar, TextareaAutosize } from "@mui/material";
import { Link, useParams, useLocation, useMatch, useNavigate } from "react-router-dom";
import { Cookies, CookiesProvider, useCookies } from "react-cookie";
import moment from "moment";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import ReactDOM from "react-dom";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import { timeConverter } from "../../../../helper/helperFunctions";
// import BackendFooter from "../../../Common/BackendFooter/BackendFooter";
// import BackendHeader from "../../../Common/BackendHeader/BackendHeader";
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";

const AvailableSlots = () => {


  const currentUser = useSelector((state) => state.loginUserKey.userInfo.isDev)
  const [anchorEl, setAnchorEl] = useState(null)
  const openCalendarInfo = Boolean(anchorEl);

  const handleCalendarInfoClose = (path) => {
    setAnchorEl(null);
    if (path) navigate(`/${path}`)
  }


  var reqestdatasetpece = {};

  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  console.log("cookies in layout==>", getcookies);

  const currenttimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var currenttimezonename = "";

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const abbrs = {
    HST: 'Hawaii Standard Time',
    EDT: 'Eastern Daylight Time',
    CST: 'Central Standard Time',
    CDT: 'Central Daylight Time',
    MST: 'Mountain Standard Time',
    MDT: 'Mountain Daylight Time',
    PST: 'Pacific Standard Time',
    PDT: 'Pacific Daylight Time',
    IST: 'India Standard Time',
    AKDT: 'Alaska Standard Time'
  };

  function selectTimeZone(currenttimezone) {
    let currenttimezonename;
    switch (currenttimezone) {
      case '+05:30|Asia/Calcutta':
        currenttimezonename = 'Asia/Kolkata';
        break;
      case '+05:30|Asia/Kolkata':
        currenttimezonename = 'Asia/Kolkata';
        break;
      case '-08:00|America/Anchorage':
        currenttimezonename = 'America/Anchorage';
        break;
      case '-07:00|America/Los_Angeles':
        currenttimezonename = 'America/Los_Angeles';
        break;
      case '-07:00|America/Denver':
        currenttimezonename = 'America/Phoenix';
        break;
      case '-07:00|America/Phoenix':
        currenttimezonename = 'America/Phoenix';
        break;
      case '-05:00|America/Chicago':
        currenttimezonename = 'America/Chicago';
        break;
      case '-04:00|America/New_York':
        currenttimezonename = 'America/New_York';
        break;
      case '-10:00|Pacific/Honolulu':
        currenttimezonename = 'Pacific/Honolulu';
        break;
    }
    return currenttimezonename;
  }

  switch (currenttimezone) {
    case 'Asia/Calcutta':
      currenttimezonename = '+05:30|Asia/Kolkata';
      break;
    case 'Asia/Kolkata':
      currenttimezonename = '+05:30|Asia/Kolkata';
      break;
    case 'America/Anchorage':
      currenttimezonename = '-08:00|America/Anchorage';
      break;
    case 'America/Los_Angeles':
      currenttimezonename = '-07:00|America/Los_Angeles';
      break;
    case 'America/Denver':
      currenttimezonename = '-07:00|America/Phoenix';
      break;
    case 'America/Phoenix':
      currenttimezonename = '-07:00|America/Phoenix';
      break;
    case 'America/Chicago':
      currenttimezonename = '-05:00|America/Chicago';
      break;
    case 'America/New_York':
      currenttimezonename = '-04:00|America/New_York';
      break;
    case 'Pacific/Honolulu':
      currenttimezonename = '-10:00|Pacific/Honolulu';
      break;
  }
  // console.log("++currenttimezone", currenttimezonename);
  let match = useMatch("/bio-energetics-discovery/:user_id");
  let pecematch = useMatch("/pece-discovery/:user_id");
  let availableslotpathmatch = useMatch("/calendar-management/available-slots-sync-google/:code/:secrect");
  let reschedulePath = useMatch("/re-schedule-appointment/:identifier");

  let intakebiomatch = useMatch("/intake-bio-discovery/:user_id");
  let intakepecematch = useMatch("/intake-pece-discovery/:user_id");
  let newMatch = useMatch("/pece-integration-new/:user_id");
  let aspirePartnermatch = useMatch("/beto-paredes-aspire-partners/:user_id");
  const [value, setValue] = React.useState([null, null]);
  const stroreDispatch = useDispatch();
  const navigate = useNavigate();

  // const [preformdata, setPreformdata] = useState({
  //   name: "",
  //   shareholders: [{ name: "", attendeName: "" }],
  // })
  // console.log("matching route", intakepecematch)
  var paramId = "";
  let preformdata;

  ////////////////////////// Setting attende data by default //////////////////
  if (getcookies.middleware !== undefined) {
    preformdata = {
      name: "",
      shareholders: [{ name: getcookies.middleware.email, attendeName: `${getcookies.middleware.first_name} ${getcookies.middleware.last_name}` }],
    }
  } else {
    preformdata = {
      name: "",
      shareholders: [{ name: "", attendeName: "" }],
    }
  }

  const [open, setOpen] = React.useState(false);
  const [skipVal, setskipVal] = React.useState(0);
  const [devflag, setdevflag] = React.useState(false);
  const [clickableData, setclickableData] = React.useState({});
  const [formArrayData, setformArrayData] = React.useState(preformdata);
  const [snakbaropen, setsnakbaropen] = React.useState(false);
  const [timezone, settimezone] = React.useState(currenttimezonename);
  const [convertedTimezone, setConvertedTimezone] = React.useState(currenttimezone)
  const [daterangeValue, setdaterangeValue] = React.useState([null, null]);
  const [bookNowSubmissionState, setBookNowSubmissionState] = useState(0);
  const [rescheduleParamID, setRescheduleParamID] = useState(null);
  const [notes, setNotes] = useState("");
  const [cancelslotFlag, setcancelslotFlag] = useState(true);
  const [locationPath, setLocationPath] = useState('')

  useEffect(() => {
    if (getcookies != undefined && getcookies.userInfo != undefined && getcookies.userInfo.isDev != undefined && getcookies.userInfo.isDev == 1) {
      setdevflag(true);
    }
    console.log("devflag", devflag)

  }, [devflag])

  const handleChange = (event) => {
    settimezone(event.target.value);
    // console.log("event 66", event.target.value)
    const timezone = selectTimeZone(event.target.value);
    // console.log("convertedTimezone///////// 66", timezone)
    setConvertedTimezone(timezone);
  };
  // console.log("convertedTimezone///////// 66", convertedTimezone)
  const handleOpen = (evevntsData) => {
    console.log("evevntsData", evevntsData)
    if (rescheduleFlag) {

      let emailattendeedata = JSON.parse(JSON.stringify(attendeeemaildata));
      for (let loop in emailattendeedata) {
        emailattendeedata[loop].name = attendeeemaildata[loop].email;
        emailattendeedata[loop].attendeName = attendeeemaildata[loop].name;
      }
      preformdata = {
        name: "",
        shareholders: emailattendeedata
      }
      // setformArrayData({
      //   name: "",
      //   shareholders: [{ name: "", attendeName: "" }],
      // });

      // setformArrayData({ shareholders: newShareholders });
    }
    setformArrayData(preformdata)
    setclickableData(evevntsData);
    // console.log("clickableDataclickableData", clickableData)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  getcookies.google_calendar_connect_time = parseInt(getcookies.google_calendar_connect_time);
  getcookies.google_calendar_connect_time = moment(getcookies.google_calendar_connect_time).format("DD-MMM-YYYY")
    ;
  const [connectgmail, setconnectgmail] = useCookies(["connectgmail"]);
  const [connectgmailaccount, setconnectgmailaccount] = useState(getcookies.connected_gmail);
  const [googlecaledersynctime, setgooglecaledersynctime] = useState(getcookies.google_calendar_connect_time);

  // console.log("getcookies.google_calendar_connect_time", getcookies.google_calendar_connect_time)
  const code = useParams();
  var accessCode = "";
  var location = useLocation();
  var pathFlag = true;
  var pecepathFlag = false;
  var reshedulePecepathFlag = false;
  var betopersonalpathFlag = false;
  var resheduleBetopersonalpathFlag = false;
  var biopathFlag = false;
  var resheduleBiopathFlag = false;
  var intakebiopathFlag = false;
  var intakepecepathFlag = false;
  var rescheduleFlag = false;
  var aspirePartnersFlag = false;
  var rescheduleAspirePartnersFlag = false;

  switch (location.pathname) {
    case "/bio-energetics-discovery-call":
      pathFlag = false;
      biopathFlag = true;
      paramId = "627d0c580bbdabff170cd79f";
      break;
    case "/pece-discovery":
      pathFlag = false;
      pecepathFlag = true;
      paramId = "627babf034b56402d1e77347";
      break;
    case "/pece-integration":
      pathFlag = false;
      intakepecepathFlag = true;
      paramId = "627d0d3e0bbdabff170cd7a1";
      break;
    // case "/pece-integration":
    // pathFlag = false;
    // intakepecepathFlag = true;
    // paramId = "627d0d3e0bbdabff170cd7a1";
    // break;
    case "/bio-energetics-integration":
      pathFlag = false;
      intakebiopathFlag = true;
      paramId = "627d0cdc0bbdabff170cd7a0";
      break;
    case "/beto-paredes-llc":
      pathFlag = false;
      betopersonalpathFlag = true;
      paramId = "629dbe769bc4d7a3761c979d";
      break;
    case "/beto-paredes-aspire-partners":
      aspirePartnersFlag = true;
      pathFlag = false;
      // betopersonalpathFlag = true;
      paramId = "6372200d1d6c8445519c6666";
      break;
    default:
      break;
  }
  // if (location.pathname === "/pece-discovery" || location.pathname === "/bio-energetics-discovery") {
  if (match != undefined && match.pattern.path === '/bio-energetics-discovery/:user_id') {
    pathFlag = false;
    biopathFlag = true;
    paramId = match.params.user_id;
    // console.log("pathFlag==true", pathFlag);
  }
  if (newMatch != undefined && newMatch.pattern.path === '/pece-integration-new/:user_id') {
    pathFlag = false;
    intakepecepathFlag = true;
    paramId = newMatch.params.user_id;
    // console.log("pathFlag==true", pathFlag);
  }

  if (pecematch && pecematch.pattern.path === '/pece-discovery/:user_id') {
    pathFlag = false;
    pecepathFlag = true;
    paramId = pecematch.params.user_id;
    // console.log("pathFlag==true", pathFlag);
  }
  // intake-bio
  if (intakebiomatch && intakebiomatch.pattern.path === '/intake-bio-discovery/:user_id') {
    pathFlag = false;
    intakebiopathFlag = true;
    paramId = intakebiomatch.params.user_id;
    // console.log("pathFlag==true", pathFlag);
  }
  if (intakepecematch && intakepecematch.pattern.path === '/intake-pece-discovery/:user_id') {
    pathFlag = false;
    intakepecepathFlag = true;
    paramId = intakepecematch.params.user_id;
    // console.log("pathFlag==true", pathFlag);
  }
  if (aspirePartnermatch && aspirePartnermatch.pattern.path === '/beto-paredes-aspire-partners/:user_id') {
    aspirePartnersFlag = true;
    pathFlag = false;
    paramId = aspirePartnermatch.params.user_id;
    // console.log("pathFlag==true", pathFlag);
  }


  // console.log("location.pathname 666", location.pathname.replace('/', ''));
  console.log("availableslotpathmatch 666", availableslotpathmatch);
  useEffect(() => {
    if (availableslotpathmatch && availableslotpathmatch.pattern && availableslotpathmatch.pattern.path === "/calendar-management/available-slots-sync-google/:code/:secrect") {

      // console.log("09090900", location);
      accessCode = code.code + "/" + code.secrect;
      // console.log("AvailableSlots codes", accessCode);
      var requestDataforgoogleapi = {
        "user_id": getcookies.userInfo?._id,
        "code": accessCode
      }
      stroreDispatch(updateGoogleTokenGmail(requestDataforgoogleapi));
    };
    // console.log("code ", window.location.pathname);
  }, []);
  // useEffect(() => {
  // console.log("reschedulePath 666", reschedulePath)
  if (reschedulePath && reschedulePath.pattern?.path === '/re-schedule-appointment/:identifier') {
    rescheduleFlag = true;
    pathFlag = false;
    // biopathFlag = false;
    // pecepathFlag = false;
    // intakebiopathFlag = false;
    // intakepecepathFlag = false;
    // setLoaderFlag(true);

    // intakepecepathFlag = true;
    // paramId = reschedulePath.params.identifier;
    // const requestbody = {
    //   "identifier": reschedulePath.params.identifier
    // }
    // console.log("reschedulePath 666", reschedulePath)
    // // seidentifierdata(paramId);
    // stroreDispatch(fetchEventWithIdentifier(requestbody));
  }

  // }, []);
  var reqestdatasetbio;
  const [calenderEventsData, setcalenderEventsData] = useState([]);
  const [loaderFlag, setloaderFlag] = useState(true);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const [rescheduleSubmissionState, setrescheduleSubmissionState] = useState(0);
  const [attendeeemaildata, setattendeeemaildata] = useState([]);
  // const requestData = { ...reqData };
  const requestData = useRef({
    source: "events_eventdayarr_view",
    condition: {
      // "userid": getcookies.userInfo._id,

    },
    "limit": 20,
    "skip": 0,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
  });
  const pecedatasetloadmore = useRef({
    "source": "events_eventdayarr_view",
    "condition": {
      "userid1": "627d0d3e0bbdabff170cd7a1",

    },
    "limit": 100,
    "skip": 0,
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
  });

  const biodatasetloadmore = useRef({
    "source": "events_eventdayarr_view",
    "condition": {
      "userid1": "627d0d3e0bbdabff170cd7a1",
      "userid2": "627d0cdc0bbdabff170cd7a0"

    },
    "limit": 100,
    "skip": 0,
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
  });


  // if (currenttimezonename != "") {
  //   // console.log("testingg")
  //   requestData.current['timezone'] = currenttimezonename;
  // }
  if (paramId !== "") {
    requestData.current.condition['userid'] = paramId;
  } else {
    requestData.current.condition['userid'] = getcookies.userInfo?._id;
  }




  useEffect(() => {
    // console.log("requestData.current 666", requestData.current)
    console.log("rescheduleParamID formd", rescheduleParamID)

    if (rescheduleParamID) {
      requestData.current.condition['userid'] = rescheduleParamID
      // console.log("calll 6666")
      stroreDispatch(fetchCalenderEvents(requestData.current));
    }
  }, [rescheduleParamID])
  useEffect(() => {

    if (rescheduleFlag) {
      const requestbody = {
        "identifier": reschedulePath.params.identifier
      }
      stroreDispatch(fetchEventWithIdentifier(requestbody));
      console.log("if 666")
    } else {
      console.log("else 666")
      if (intakebiopathFlag == true) {
        reqestdatasetbio = {
          "source": "events_eventdayarr_view",
          "condition": {
            "userid1": "627d0d3e0bbdabff170cd7a1",
            "userid2": "627d0cdc0bbdabff170cd7a0"

          },
          "limit": 100,
          "skip": 0,
          "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
        }
        stroreDispatch(fetchCalenderIntegrationEvents(reqestdatasetbio));

      } else if (intakepecepathFlag == true) {
        if (!location.pathname.includes('627d0cdc0bbdabff170cd7a0')) {
          reqestdatasetpece = {
            "source": "events_eventdayarr_view",
            "condition": {
              "userid1": "627d0d3e0bbdabff170cd7a1"
            },
            "limit": 100,
            "skip": 0,
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
          }

          console.log("ttttttt", reqestdatasetpece);
          stroreDispatch(fetchCalenderPECEIntegrationEvents(reqestdatasetpece));
        } else {
          reqestdatasetpece = {
            "source": "events_eventdayarr_view",
            "condition": {
              "userid": "627d0cdc0bbdabff170cd7a0",
              "event_type": 4
            },
            "limit": 100,
            "skip": 0,
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
          }
          console.log("ttttttt", reqestdatasetpece);
          stroreDispatch(fetchCalenderEventsLoadMore(reqestdatasetpece));
        }


      } else {
        stroreDispatch(fetchCalenderEvents(requestData.current));
      }
    }
  }, [rescheduleFlag])

  useEffect(() => {
    // console.log("calenderEventsData", calenderEventsData);
  }, [calenderEventsData])
  // useEffect(() => {
  // console.log("locationPath", locationPath);

  //     reshedulePecepathFlag
  // resheduleBetopersonalpathFlag
  // resheduleBiopathFlag

  if (locationPath === 'beto-paredes-llc') {
    resheduleBetopersonalpathFlag = true;
  } else if (location.pathname.includes("re-schedule-appointment")) {
    resheduleBetopersonalpathFlag = true;
  } else if (locationPath === 'pece-discovery') {
    reshedulePecepathFlag = true;
  } else if (locationPath === 'bio-energetics-discovery-call') {
    resheduleBiopathFlag = true;
  } else if (locationPath === 'beto-paredes-aspire-partners') {
    rescheduleAspirePartnersFlag = true;
  }

  useEffect(() => {
    console.log("location", location);
  }, [location])
  // console.log("locationPath", resheduleBetopersonalpathFlag);
  // console.log("locationPath", reshedulePecepathFlag);
  // console.log("locationPath", resheduleBiopathFlag);
  // }, [locationPath])

  store.subscribe(() => {

    const storeData = store.getState();
    console.log("store Data", storeData);
    if (storeData.calenderEventsStore.fetchIdentifierData && Object.keys(storeData.calenderEventsStore.fetchIdentifierData).length > 0) {
      // console.log("storeData.calenderEventsStore.fetchIdentifierData.condition.userid 666", storeData.calenderEventsStore.fetchIdentifierData)
      if (storeData.calenderEventsStore.fetchIdentifierData.status === 2) {
        setcancelslotFlag(false);
      } else {
        setLocationPath(storeData.calenderEventsStore.fetchIdentifierData.locationPath)
        setattendeeemaildata(storeData.calenderEventsStore.fetchIdentifierData.atten_email)
        if (storeData.calenderEventsStore.fetchIdentifierData.condition.userid) {
          setRescheduleParamID(storeData.calenderEventsStore.fetchIdentifierData.condition.userid);
        } else {
          setRescheduleParamID(storeData.calenderEventsStore.fetchIdentifierData.userid);
          // if (storeData.calenderEventsStore.fetchIdentifierData.userid) {
          //   setLocationPath(storeData.calenderEventsStore.fetchIdentifierData.locationPath)
          //   setattendeeemaildata(storeData.calenderEventsStore.fetchIdentifierData.atten_email)
          // }
        }
      }
    }
    setcalenderEventsData(storeData.calenderEventsStore.calenderEventsData);
    setloaderFlag(storeData.calenderEventsStore.loading);
    setBookNowSubmissionState(storeData.calenderEventsStore.bookNowSubmissionState);
    setrescheduleSubmissionState(storeData.calenderEventsStore.rescheduleSubmissionState);
    if (storeData.calenderEventsStore.booknowData === 'success') {
      setsnakbaropen(true);
      setOpen(false);
      setSnackBarMsg(storeData.calenderEventsStore.booknowMsg)
    }
    if (storeData.calenderEventsStore.rescheduleData === 'success' && storeData.calenderEventsStore.rescheduleSubmissionState === 2) {
      setsnakbaropen(true);
      setOpen(false);
      setSnackBarMsg(storeData.calenderEventsStore.rescheduleMsg)
    }
    // console.log("[]][]]][", storeData);
    if (storeData.calenderEventsStore.syncGoogleAcountData && storeData.calenderEventsStore.syncGoogleAcountData !== {} && storeData.calenderEventsStore.syncGoogleAcountData.connected_gmail !== undefined) {
      setconnectgmailaccount(storeData.calenderEventsStore.connectedgmail);
      var gooleconnecttime = storeData.calenderEventsStore.syncGoogleAcountData.google_calendar_connect_time;
      gooleconnecttime = parseInt(storeData.calenderEventsStore.syncGoogleAcountData.google_calendar_connect_time)
      gooleconnecttime = moment(storeData.calenderEventsStore.syncGoogleAcountData.google_calendar_connect_time).format("DD-MMM-YYYY");
      // console.log("gooleconnecttime", gooleconnecttime)
      setgooglecaledersynctime(storeData.calenderEventsStore.gooleconnecttime);
      setconnectgmail('connected_gmail', storeData.calenderEventsStore.connectedgmail, { path: '/' });
    }

  });

  ////////////////////////// On Book now submission //////////////////
  useEffect(() => {
    console.log("bookNowSubmissionState before");
    if (bookNowSubmissionState === 2) {
      console.log("bookNowSubmissionState");
      ///////////////////////// Clear Middleware cookies ////////////////////////
      const savedCookies = new Cookies();
      savedCookies.remove("middleware", { path: "/" });

      setformArrayData({
        name: "",
        shareholders: [{ name: "", attendeName: "" }],
      });
      stroreDispatch(setBooknowSubmissionState());
      // savedCookies.remove("SlotData", { path: "/" });
      console.log("clickableData", clickableData)
      cookies.set('SlotData', clickableData, { path: '/' });

      navigate('/thankyou/booking', { replace: true });
    }
  }, [bookNowSubmissionState]);

  ////////////////////////// On Re-Schedule submission //////////////////
  useEffect(() => {
    console.log("rescheduleSubmissionState before", rescheduleSubmissionState);
    if (rescheduleSubmissionState === 2) {
      console.log("rescheduleSubmissionState");
      ///////////////////////// Clear Middleware cookies ////////////////////////
      const savedCookies = new Cookies();
      savedCookies.remove("middleware", { path: "/" });

      setformArrayData({
        name: "",
        shareholders: [{ email: "", attendeName: "" }],
      });
      stroreDispatch(setrescheduleSubmissionStateReset());
      // savedCookies.remove("SlotData", { path: "/" });
      cookies.set('SlotData', clickableData, { path: '/' });
      navigate('/thankyou/reschedule', { replace: true });
      // resetFunction();
    }
  }, [rescheduleSubmissionState]);

  useEffect(() => {
    if (snakbaropen === true) {
      setTimeout(() => {
        setsnakbaropen(false);
        setSnackBarMsg('');
      }, 6000)
    }
  }, [snakbaropen]);

  const handleAddShareholder = () => {
    let concatdata = {
      shareholders: formArrayData.shareholders.concat([{ name: "", attendeName: "" }]),
    }
    // console.log("concatdata", concatdata);
    setformArrayData(concatdata);
  };
  const handleShareholderNameChange = (idx) => (evt) => {
    // console.log("testio", idx, evt.target.value, formArrayData.shareholders);
    const newShareholders = formArrayData.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, name: evt.target.value };
    });

    setformArrayData({ shareholders: newShareholders });
  };

  const handleNotesChange = (event) => {
    // console.log("event textarea", event.target.value, event);
    setNotes(event.target.value);
  }

  const handleattendeeNameChange = (idx) => (evt) => {
    // console.log("testio", idx, evt.target.value, formArrayData.shareholders);
    const newShareholders = formArrayData.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, attendeName: evt.target.value };
    });

    setformArrayData({ shareholders: newShareholders });
  };

  const handleRemoveShareholder = (idx) => () => {
    // console.log("testio8989", idx)
    setformArrayData({
      shareholders: formArrayData.shareholders.filter((s, sidx) => idx !== sidx),
    });
  };
  //////////////////// Handle Load More /////////////////
  const handleLoadMore = () => {


    console.log("testing pece path", requestData)
    // return;
    if (rescheduleParamID) {
      requestData.current.condition['userid'] = rescheduleParamID
    }
    requestData.current.skip = requestData.current.skip + requestData.current.limit;

    // console.log("Skip", requestData);
    if (intakebiopathFlag == true) {
      console.log("intakebiopathFlag", intakebiopathFlag)
      biodatasetloadmore.current.skip = biodatasetloadmore.current.skip + biodatasetloadmore.current.limit;
      if (daterangeValue != undefined && daterangeValue[0] != null) {
        let start_date = daterangeValue[0].getTime();
        let end_date = daterangeValue[1].getTime();
        let nowtime = new Date().getTime()
        if (nowtime > start_date) {
          start_date = nowtime
        }
        biodatasetloadmore.current.condition['start_date'] = start_date;
        biodatasetloadmore.current.condition['end_date'] = (end_date + (24 * 3600 * 1000)) - 1000;
      }
      stroreDispatch(fetchCalenderIntegrationEvents(biodatasetloadmore.current));

    } else if (intakepecepathFlag == true) {
      console.log("skipVal", pecedatasetloadmore)
      pecedatasetloadmore.current.skip = pecedatasetloadmore.current.skip + pecedatasetloadmore.current.limit;
      if (daterangeValue != undefined && daterangeValue[0] != null) {
        let start_date = daterangeValue[0].getTime();
        let end_date = daterangeValue[1].getTime();
        let nowtime = new Date().getTime()
        if (nowtime > start_date) {
          start_date = nowtime
        }
        pecedatasetloadmore.current.condition['start_date'] = start_date;
        pecedatasetloadmore.current.condition['end_date'] = (end_date + (24 * 3600 * 1000)) - 1000;
      }
      if (location.pathname.includes('627d0cdc0bbdabff170cd7a0')) {
        pecedatasetloadmore.current.condition['userid1'] = '627d0cdc0bbdabff170cd7a0'
      }

      // stroreDispatch(fetchCalenderPECEIntegrationEvents(pecedatasetloadmore.current));
      stroreDispatch(fetchCalenderPECEIntegrationEventsmore(pecedatasetloadmore.current));
    } else {
      stroreDispatch(fetchCalenderEventsLoadMore(requestData.current));
    }
  }
  console.log("formArrayData 999", formArrayData)
  /////////////////////////// Book Now Form Submission ////////////////////////
  const booknowformsubmit = () => {
    // console.log("formArrayData.shareholders", formArrayData.shareholders);
    // return;
    let attendeeemaildataset = [];
    for (let i in formArrayData.shareholders) {
      // console.log("in lopp", i)
      if (formArrayData.shareholders[i].name !== null & formArrayData.shareholders[i].name !== '') {
        attendeeemaildataset.push({ 'name': formArrayData.shareholders[i].attendeName, 'email': formArrayData.shareholders[i].name });
      }

    }

    let formdata = {};
    formdata = JSON.parse(JSON.stringify(clickableData));
    // console.log("formdata", formdata.userid)
    // console.log("formdata rescheduleParamID", rescheduleParamID)
    // if (rescheduleParamID) {
    //   temCondition['userid'] = formdata.userid;
    //   // requestData.current.condition.userid = rescheduleParamID
    // }
    // console.log("formdata ++++", formdata)

    // formdata.condition = requestData.current.condition;
    if (requestData.current.condition.userid !== undefined) {
      formdata.condition = requestData.current.condition
    } else {
      formdata.condition = { userid: formdata.userid };
    }
    delete formdata._id;
    formdata.atten_email = attendeeemaildataset;
    // formdata.bookingUserEmail = formdata.bookingUserEmail;
    formdata.notes = notes;
    if (rescheduleFlag) {
      formdata.identifier = reschedulePath.params.identifier
    }
    formdata.locationPath = location.pathname.replace('/', '');
    formdata.user_timezone = convertedTimezone;
    setclickableData(formdata);
    Object.preventExtensions(formdata);
    if (rescheduleFlag) {
      stroreDispatch(rescheduleevents(formdata));
    } else {
      stroreDispatch(booknowevents(formdata));
    }
    console.log("formdata", formdata)
    // console.log("requestData", requestData);
  }

  ////////////////////////// reset function //////////////////////////////
  const resetFunction = () => {
    console.log("search hit 2");
    settimezone(currenttimezonename);
    setdaterangeValue([null, null]);
    // console.log("requestData.current", requestData.current);
    // requestData.current.condition = {};
    if (rescheduleParamID) {
      requestData.current.condition['userid'] = rescheduleParamID
    }
    delete requestData.current.condition['start_date'];
    delete requestData.current.condition['end_date'];


    requestData.current.skip = 0;
    if (intakebiopathFlag == true) {
      console.log("bio flagg +++++");
      var reqestdataset = {
        "source": "events_eventdayarr_view",
        "condition": {
          "userid1": "627d0d3e0bbdabff170cd7a1",
          "userid2": "627d0cdc0bbdabff170cd7a0"

        },
        "limit": 100,
        "skip": 0,
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
      }
      stroreDispatch(fetchCalenderIntegrationEvents(reqestdataset));

    } else if (intakepecepathFlag == true) {
      console.log("search hit intakepecepathFlag");
      if (!location.pathname.includes('627d0cdc0bbdabff170cd7a0')) {
        var reqestdataset = {
          "source": "events_eventdayarr_view",
          "condition": {
            "userid1": "627d0d3e0bbdabff170cd7a1",

          },
          "limit": 100,
          "skip": 0,
          "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
        }
        stroreDispatch(fetchCalenderPECEIntegrationEvents(reqestdataset));
      } else {
        var reqestdataset = {
          "source": "events_eventdayarr_view",
          "condition": {
            "userid": "627d0cdc0bbdabff170cd7a0",
            "event_type": 4

          },
          "limit": 100,
          "skip": 0,
          "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
        }
        stroreDispatch(fetchCalenderEventsLoadMore(reqestdataset));
      }


    } else {
      stroreDispatch(fetchCalenderEvents(requestData.current));

    }
    // {
    // }
  }

  //////////////////////// Search Events ///////////////////////////////
  const searchFunction = () => {
    console.log("search hitt");
    if (daterangeValue != undefined && daterangeValue[0] != null) {
      console.log("search hitt date range present");

      requestData.current.skip = 0;
      let start_date = daterangeValue[0].getTime();
      let end_date = daterangeValue[1].getTime();
      let nowtime = new Date().getTime()
      if (nowtime > start_date) {
        start_date = nowtime
      }
      console.log("start_date and  now time", start_date, nowtime);
      // console.log(start_date, "timezone", end_date);
      requestData.current.condition['start_date'] = start_date;
      requestData.current.condition['end_date'] = (end_date + (24 * 3600 * 1000)) - 1000;

    }

    // if (timezone != undefined && timezone != null && timezone != "") {
    //   requestData.current['timezone'] = timezone;

    // }
    if (rescheduleParamID) {
      requestData.current.condition['userid'] = rescheduleParamID
    }
    if (daterangeValue !== undefined && daterangeValue[0] != null) {
      console.log("search hitt date range present 2nd");

      if (intakebiopathFlag == true) {
        if (!location.pathname.includes('627d0cdc0bbdabff170cd7a0')) {
          var reqestdataset = {
            "source": "events_eventdayarr_view",
            "condition": {
              "userid1": "627d0d3e0bbdabff170cd7a1",
              "userid2": "627d0cdc0bbdabff170cd7a0"

            },
            "limit": 100,
            "skip": 0,
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
          }
          let start_date = daterangeValue[0].getTime();
          let end_date = daterangeValue[1].getTime();
          let nowtime = new Date().getTime()
          if (nowtime > start_date) {
            start_date = nowtime
          }
          reqestdataset.condition['start_date'] = start_date;
          reqestdataset.condition['end_date'] = (end_date + (24 * 3600 * 1000)) - 1000;
          console.log("reqestdataset++++++++", reqestdataset);

          stroreDispatch(fetchCalenderIntegrationEvents(reqestdataset));

        } else {
          var reqestdataset = {
            "source": "events_eventdayarr_view",
            "condition": {
              "userid": "627d0cdc0bbdabff170cd7a0",
              "event_type": 4


            },
            "limit": 100,
            "skip": 0,
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
          }
          let start_date = daterangeValue[0].getTime();
          let end_date = daterangeValue[1].getTime();
          let nowtime = new Date().getTime()
          if (nowtime > start_date) {
            start_date = nowtime
          }
          reqestdataset.condition['start_date'] = start_date;
          reqestdataset.condition['end_date'] = (end_date + (24 * 3600 * 1000)) - 1000;
          console.log("reqestdataset++++++++", reqestdataset);

          stroreDispatch(fetchCalenderEventsLoadMore(reqestdataset));
        }



      } else if (intakepecepathFlag == true) {
        console.log("else intakepecepathFlag");
        if (!location.pathname.includes('627d0cdc0bbdabff170cd7a0')) {
          var reqestdataset = {
            "source": "events_eventdayarr_view",
            "condition": {
              "userid1": "627d0d3e0bbdabff170cd7a1",

            },
            "limit": 100,
            "skip": 0,
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
          }
          if (daterangeValue != undefined && daterangeValue[0] != null) {
            let start_date = daterangeValue[0].getTime();
            let end_date = daterangeValue[1].getTime();
            let nowtime = new Date().getTime()
            if (nowtime > start_date) {
              start_date = nowtime
            }
            reqestdataset.condition['start_date'] = start_date;
            reqestdataset.condition['end_date'] = (end_date + (24 * 3600 * 1000)) - 1000;
          }
          stroreDispatch(fetchCalenderPECEIntegrationEvents(reqestdataset));
        } else {
          var reqestdataset = {
            "source": "events_eventdayarr_view",
            "condition": {
              "userid": "627d0cdc0bbdabff170cd7a0",
              "event_type": 4

            },
            "limit": 100,
            "skip": 0,
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM"
          }
          if (daterangeValue != undefined && daterangeValue[0] != null) {
            let start_date = daterangeValue[0].getTime();
            let end_date = daterangeValue[1].getTime();
            let nowtime = new Date().getTime()
            if (nowtime > start_date) {
              start_date = nowtime
            }
            reqestdataset.condition['start_date'] = start_date;
            reqestdataset.condition['end_date'] = (end_date + (24 * 3600 * 1000)) - 1000;
          }
          // stroreDispatch(fetchCalenderEventsLoadMore(reqestdataset));
          stroreDispatch(fetchCalenderEventsLoadMorenoconcat(reqestdataset));
        }



      } else {
        stroreDispatch(fetchCalenderEvents(requestData.current));

      }
    }

  };

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <>

      {/* {/ Meta Service Start Here /} */}
      {/* <MetaTags>
        <title>Available Slot</title>
        <meta id="meta-description" name="description" content="One with Dawn for BioEnergetics, Michael for PECE, Integration meeting for PECE and another integration meeting for BioEnergetics." />
        <meta id="og-title" property="og:title" content="Available Slot" />
        <meta
          id="og-image"
          property="og:image"
          content="path/to/og_image.jpg"
        />
      </MetaTags> */}
      {/* {/ Meta Service End Here /} */}
      {cancelslotFlag === true ?
        <div className="mainContentWrapper form">
          {
            biopathFlag == true && (
              <Helmet>
                <title>BioEnergetics discovery webcast</title>
                <meta name="description" content="Book your discovery call for BioEnergetics platform with Beto Paredes by choosing slots and adding notes for him." data-react-helmet="true"></meta>
              </Helmet>
            )
          }
          {
            pecepathFlag == true && (
              <Helmet>
                <title>PECE portal discovery webcast</title>
                <meta name="description" content="Book your discovery call for PECE™ with Michael White by choosing slots and adding notes for him." data-react-helmet="true"></meta>
              </Helmet>
            )
          }
          {
            betopersonalpathFlag == true && (
              <Helmet>
                <title>Appointment with Beto Paredes</title>
                <meta name="description" content="Book your appointment with Beto Paredes." data-react-helmet="true"></meta>
              </Helmet>
            )
          }

          {/* bio banner section */}
          {
            biopathFlag == true && (
              <div className="ContentWrapper BioEnergetics_banner1">
                <div className="BioEnergetics_banner1_bg">
                  <div className="banner_textblock">
                    <div className="banner_textblock_logo"><span><img src='https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_logo.png' /></span></div>
                    <h2>BOOK YOUR DISCOVERY WEB CAST WITH BETO PAREDES FOR THE BIOENERGETICS PLATFORM
                      {/* <p>BOOK YOUR DISCOVERY WEB CAST WITH BETO PAREDES FOR THE BIOENERGETICS PLATFORM.</p> */}
                    </h2>
                  </div>
                </div>
              </div>


            )
          }
          {
            aspirePartnersFlag === true && (
              <div className="ContentWrapper BioEnergetics_banner1">
                <div className="BioEnergetics_banner1_bg">
                  <div className="banner_textblock">
                    <div className="banner_textblock_logo">
                      <span><img src='https://betoparedes.com/images/as_betologo.png' alt="as_betologo" /></span>
                      <span className="logo_content">in association with Aspire Partners</span>
                    </div>
                    <h2>Meeting with Beto Paredes to discuss the Aspire Partners tax and expense reduction programs</h2>
                  </div>
                </div>
              </div>


            )
          }
          {/* pece banner section */}
          {
            pecepathFlag == true && (
              <div className="ContentWrapper PECE_banner1">
                <div className="PECE_banner1_bg">
                  <div className="banner_textblock">
                    {!location.pathname.includes('629f57f8ac8244773d9bc018') && (

                      <div className="banner_textblock_logo"><span><img src='https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_logo.png' alt="PECE_logo" /></span></div>
                    )}
                    {!location.pathname.includes('629f57f8ac8244773d9bc018') && !location.pathname.includes('632868d6dc35759c6f3afa8c') && (

                      // <h2>PECE portal discovery webcast with Michael White
                      //   <p>Now you can book your discovery call for PECE™ with one of our senior consultants Michael White by choosing slots and adding notes for the closer (senior).</p>
                      // </h2>
                      <h2>Meeting With Nathan Campbell With PECE Platform</h2>
                    )}
                    {location.pathname.includes('629f57f8ac8244773d9bc018') && (

                      <h2>Book your event with Debasis Kar

                      </h2>
                    )}

                    {location.pathname.includes('632868d6dc35759c6f3afa8c') && (

                      <h2>PECE portal discovery webcast with Nadine Varela
                        <p>Now you can book your discovery call for PECE™ with one of our senior consultants Nadine Varela by choosing slots and adding notes for the closer (senior).</p>
                      </h2>
                    )}
                  </div>

                </div>
              </div>
            )
          }

          {/* ------------------------------------------------rescheduleFlag-------------------------------------------------- */}


          {/* integration meeting bio */}
          {
            intakebiopathFlag == true && (
              <div className="ContentWrapper PECE_banner1">
                <div className="PECE_banner1_bg">
                  <div className="banner_textblock">
                    <div className="banner_textblock_logo"><span><img src='https://all-frontend-assets.s3.amazonaws.com/calendermanagementreact/assets/images/BioEnergetics_banner2.png' /></span></div>
                    <h2>BioEnergetics Integration Meeting
                      <p>You can schedule a call by chosing slots and adding notes.</p>
                    </h2>
                  </div>
                </div>
              </div>
            )
          }
          {/* integration meeting pece */}
          {
            intakepecepathFlag == true && (
              <div className="ContentWrapper PECE_banner1">
                <div className="PECE_banner1_bg">
                  {!location.pathname.includes('627d0cdc0bbdabff170cd7a0') && (
                    <div className="banner_textblock">
                      <div className="banner_textblock_logo"><span><img src='https://all-frontend-assets.s3.amazonaws.com/calendermanagementreact/assets/images/PECE_banner1.png' /></span></div>
                      <h2>PECE™ Integration Meeting
                        <p>You can schedule a call by chosing slots and adding notes.</p>
                      </h2>
                    </div>
                  )}
                  {location.pathname.includes('627d0cdc0bbdabff170cd7a0') && (
                    <div className="banner_textblock">
                      <div className="banner_textblock_logo"><span><img src='https://all-frontend-assets.s3.amazonaws.com/calendermanagementreact/assets/images/PECE_banner1.png' /></span></div>
                      <h2>PECE™ Integration Meeting With Jared
                        <p>You can schedule a call by chosing slots and adding notes.</p>
                      </h2>
                    </div>
                  )}


                </div>
              </div>
            )
          }
          {/* beto paredes personal meeting */}
          {
            betopersonalpathFlag == true && (


              <img src='https://all-frontend-assets.s3.amazonaws.com/calendermanagementreact/assets/images/beto_banner.png' />
              // <div className="ContentWrapper PECE_banner1">
              //   <div className="PECE_banner1_bg">
              //     <div className="banner_textblock">
              //       <div className="banner_textblock_logo"><span><img src='https://all-frontend-assets.s3.amazonaws.com/calendermanagementreact/assets/images/Banner_beto.jpg' /></span></div>
              //       <h2>PECE™ Integration Meeting
              //         <p>BETO PARADES</p>
              //       </h2>
              //     </div>
              //   </div>
              // </div>
            )
          }




          <div className="ContentWrapper">
            <div className="Formdiv">
              {/* {/ Login Form Start Here /} */}
              <div className="FormBlock">
                {/* {/ connected email section /} */}
                {pathFlag === true && <div>
                  <div className="connectedemail">
                    <h2>Calendar Management available slots</h2>
                    <div className="calenderinfo">
                      <p>
                        CONNECTED WITH GOOGLE CALENDAR <br /> LAST UPDATED ON:{googlecaledersynctime} <br />
                        EMAIL CONNECTED: <span>{connectgmailaccount}</span>
                      </p>
                    </div>
                  </div>
                  <div className="buttonsetToolbar">
                    <Button variant="contained">
                      <Link to="/calendar-management-createEvent">
                        Create New Availability
                      </Link>
                    </Button>
                    <Button variant="contained">
                      <Link to="/calendar-management-event-listing">
                        Availability List
                      </Link>
                    </Button>
                    <Button
                      href="https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/sync-calendar"
                      variant="contained"
                    >
                      Update Google Account
                    </Button>
                    <Button variant="contained">
                      <Link to="/calendar-management-booked-slots">
                        Booked Slot
                      </Link>
                    </Button>

                    {devflag && <Button variant="contained">
                      <Link to="/events-slot-summery">
                        Event Summary
                      </Link>
                    </Button>}

                    {currentUser ? <Button variant="contained" id="button">
                      <Link to="/calendar-management-manageUser">
                        Manage User
                      </Link>
                    </Button> : ''}
                    {devflag && <>
                      <Button variant="contained" onClick={(event) => setAnchorEl(event.currentTarget)}>
                        Developer Info
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openCalendarInfo}
                        onClose={() => handleCalendarInfoClose()}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={() => handleCalendarInfoClose('developer-info/pece-dev')}>Pece Developement</MenuItem>
                        <MenuItem onClick={() => handleCalendarInfoClose('developer-info/pece-live')}>Pece Live</MenuItem>
                        <MenuItem onClick={() => handleCalendarInfoClose('developer-info/calendar-management')}>Calendar Management</MenuItem>
                      </Menu>
                    </>}
                  </div>
                </div>}

                <Modal className="modalblock bookingModal"
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Button type="button" className="closeModalBtn" onClick={closeModal}>x</Button>
                    <Box className="modalBox">
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        {clickableData.event_title}
                      </Typography>
                      <div id="modal-modal-description" sx={{ mt: 2 }}>
                        <div className="calender_block bookNowModal">
                          <p>
                            <span> Booking Date : </span>
                            {timeConverter({ time: new Date(clickableData.startDateTime), format: "L", timezone: convertedTimezone })}
                          </p>
                          {/* {clickableData.startdate} */}
                          <p>
                            <span>Slot : </span>
                            {timeConverter({ time: new Date(clickableData.startDateTime), format: "h:mm:ss A", timezone: convertedTimezone })} -{" "}
                            {timeConverter({ time: new Date(clickableData.endDateTime), format: "h:mm:ss A", timezone: convertedTimezone })}
                          </p>
                          {/* {clickableData.slot} - {clickableData.slot_end_time} */}
                          <p>
                            <span>Timezone : </span>
                            {abbrs[timeConverter({ time: new Date(clickableData.endDateTime), format: "zz", timezone: convertedTimezone })]}
                          </p>
                          {/* {clickableData.timezoneName} */}
                        </div>
                      </div>
                      {/* add attendee email start */}
                      {/* <form > */}
                      {/* ... */}
                      <h4>Add Attendee</h4>
                      {formArrayData.shareholders.map((shareholder, idx) => (
                        <div className="shareholder addemailbox" key={idx}>
                          <input className="addemail"
                            type="text"
                            placeholder="Attendee Name"
                            value={shareholder.attendeName}
                            onChange={handleattendeeNameChange(idx)}
                          />
                          <input className="addemail"
                            type="text"
                            placeholder="Attendee E-mail"
                            value={shareholder.name}
                            onChange={handleShareholderNameChange(idx)}
                          />
                          <button type="button" onClick={handleRemoveShareholder(idx)} className="small addemailBTN">
                            -
                          </button>
                        </div>
                      ))}
                      <div className="modalSUBbtn_wrapper">
                        <Button type="button" onClick={handleAddShareholder} className="small modalSUBbtn" disabled={loaderFlag}>
                          Add Attendee
                        </Button>
                      </div>

                      <h4>Notes</h4>
                      <TextareaAutosize minRows={4} style={{ width: "100%", border: "solid 1px" }} onChange={(e) => handleNotesChange(e)} />
                      {loaderFlag == true ? (
                        <LinearProgress />
                      ) : (
                        ""
                      )}
                      <div className="modalSUBbtn_wrapper">
                        <Button className="modalSUBbtn"
                          href=""
                          variant="contained"
                          onClick={booknowformsubmit}
                          disabled={loaderFlag}
                        >
                          Submit
                        </Button>
                      </div>
                    </Box>
                  </Box>

                </Modal>
                {/* here is a timezone dropdown */}
                <div className="form_wrapper">
                  <div className="form_group">
                    <InputLabel id="demo-simple-select-autowidth-label">Choose Timezone</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={timezone}
                      onChange={handleChange}
                      autoWidth
                      label="Choose Timezone"
                    >
                      <MenuItem value={'-08:00|America/Anchorage'}>Alaska Standard Time</MenuItem>
                      <MenuItem value={'+05:30|Asia/Kolkata'}>India Standard Time</MenuItem>
                      <MenuItem value={'-07:00|America/Los_Angeles'}>Pacific Standard Time</MenuItem>
                      <MenuItem value={'-07:00|America/Phoenix'}>Mountain Standard Time On DTS (GMT-07:00)</MenuItem>
                      <MenuItem value={'-05:00|America/Chicago'}>Central Standard Time</MenuItem>
                      <MenuItem value={'-04:00|America/New_York'}>Eastern Standard Time</MenuItem>
                      <MenuItem value={'-10:00|Pacific/Honolulu'}>Hawaii Standard Time</MenuItem>
                    </Select>
                  </div>

                  <div className="daterangecls form_group">
                    <InputLabel id="demo-simple-select-autowidth-label">Filter By Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateRangePicker
                        startText="Start-Date"
                        endText="End-Date"
                        value={daterangeValue}
                        onChange={(newValue) => {
                          setdaterangeValue(newValue);
                        }}
                        minDate={new Date()}

                        onOpen={() => {

                          //////////////////////// Removing Missing License Key Message /////////////////////
                          setTimeout(() => {
                            if (document.querySelector('daterangecls') != undefined && document.querySelector('daterangecls').style != undefined)
                              document.querySelector('daterangecls').style.display = 'none';

                            let e = document.getElementsByTagName("div");
                            for (let i = 0; i < e.length; i++) {
                              let textval = e[i].innerText;
                              if (textval.includes('Missing license key') && textval.length < 30) {
                                e[i].style.display = 'none';
                              }

                            }
                            if (document.querySelector('daterangecls') != undefined && document.querySelector('daterangecls').style != undefined)
                              document.querySelector('daterangecls').style.display = 'block'
                          }, 200);
                        }}
                        renderInput={(startProps, endProps) => (
                          <React.Fragment>
                            <TextField {...startProps} />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextField {...endProps} />
                          </React.Fragment>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="subBTN">
                    <Button
                      href=""
                      variant="contained"
                      onClick={searchFunction}
                    >
                      <SearchIcon />
                    </Button>
                  </div>
                  <div className="subBTN">
                    <Button
                      href=""
                      variant="contained"
                      onClick={resetFunction}
                    >
                      <ReplayIcon />
                    </Button>
                  </div>
                </div>
                Showing {calenderEventsData.length} Records :

                {loaderFlag == true ? (
                  <Box sx={{ width: "100%", mt: "10px" }}>
                    <LinearProgress sx={{ height: "5px" }} />
                  </Box>
                ) : (
                  ""
                )}
                {/* {/ Form Section Starts /} */}
                <div className="calender_body formCls">
                  {calenderEventsData != undefined &&
                    calenderEventsData.length > 0 ? (
                    calenderEventsData.map((events, index) => {
                      return (
                        <div key={index} className="calender_block">
                          <h2>{events.event_title}</h2>
                          <p>
                            <span>Date :</span>
                            {timeConverter({ time: new Date(events.startDateTime), format: "L", timezone: convertedTimezone })}
                            {/* {events.startdate} */}
                          </p>
                          <p>
                            {/* <span>Slot :</span> {events.slot} -{" "}
                          {events.slot_end_time} */}
                            <span>Slot :</span> {timeConverter({ time: new Date(events.startDateTime), format: "h:mm:ss A", timezone: convertedTimezone })} -{" "}
                            {timeConverter({ time: new Date(events.endDateTime), format: "h:mm:ss A", timezone: convertedTimezone })}
                          </p>
                          <p>
                            <span>Timezone :</span> {abbrs[timeConverter({ time: new Date(events.endDateTime), format: "zz", timezone: convertedTimezone })]}
                          </p>
                          {pathFlag === false && <Button className="slotBookBtn"
                            href=""
                            variant="contained"
                            onClick={() => handleOpen(events)}
                          >
                            {rescheduleFlag ? "Re-Schedule" : "Book Now"}
                          </Button>}
                        </div>
                      );
                    })
                  ) : (
                    <p>No Records Found</p>
                  )}
                </div>
                <div className="loadMoreSection">
                  {calenderEventsData.length != 0 && !location.pathname.includes('627d0cdc0bbdabff170cd7a0') ?
                    (<Button variant="contained" className="loadMore" onClick={() => handleLoadMore()} disabled={loaderFlag}>Load More</Button>) : ""
                  }
                </div>
              </div>
            </div>
          </div>

          {loaderFlag == true ? (
            <Box sx={{ width: "100%", mt: "10px" }}>
              <LinearProgress sx={{ height: "5px" }} />
            </Box>
          ) : (
            ""
          )}

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            open={snakbaropen}
            onClose={handleClose}
            message={snackBarMsg}
            key='bottomcenter'
          />
        </div> :
        <div className='thankyou-container canceledAlready'>
          <div className='thankyou-block'>
            <div className='content'>
              <h1>Your booked slot has already been cancelled, you can no longer rescheduled from here. </h1>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default AvailableSlots;



// {
//   rescheduleFlag == true && (
//     <div className="ContentWrapper PECE_banner1">
//       <div className="PECE_banner1_bg">
//         <div className="banner_textblock">
//           <div className={`banner_textblock_logo ${resheduleBetopersonalpathFlag ? 'betoBannerLogo' : ''}`}>
//             <span>
//               {resheduleBetopersonalpathFlag ? <img src='https://all-frontend-assets.s3.amazonaws.com/calendar-management/imgpsh_fullsize_anim.png' /> : reshedulePecepathFlag ? <img src='https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_logo.png' /> : resheduleBiopathFlag ? <img src='https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_logo.png' /> : ''}
//               {/* <img src='https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_logo.png' /> */}
//             </span>
//           </div>
//           {/* {!resheduleBetopersonalpathFlag && reshedulePecepathFlag && resheduleBiopathFlag && ( */}
//           <h2>{resheduleBetopersonalpathFlag ? "" : reshedulePecepathFlag ? '' : resheduleBiopathFlag ? 'HERE YOU CAN RESCHEDULE YOUR DISCOVERY CALL WITH BETO PAREDES FOR BIOENERGETICS PLATFORM' : ''}
//             <p>{resheduleBetopersonalpathFlag ? "" : reshedulePecepathFlag ? 'Here you can easily reschedule your slot at your convenience for the ANS test, through this ANS test you will be able to know about your possible diseases and take necessary measures.' : resheduleBiopathFlag ? '' : ''}</p>
//           </h2>
//           {/* )} */}
//         </div>
//       </div>
//     </div>
//   )
// }