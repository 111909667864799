import { Button, Menu, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PeceDev from './Projects/Pece_Dev/PeceDev'
import PeceLive from './Projects/Pece_Live/PeceLive'

function DeveloperInfo() {
  const navigate = useNavigate()
  const location = useLocation();
  console.log("location====================>", location)

  const isDev = useSelector((state) => state.loginUserKey.userInfo.isDev)


  const [anchorEl, setAnchorEl] = useState(null)
  const openCalendarInfo = Boolean(anchorEl);

  const handleCalendarInfoClose = (path) => {
    setAnchorEl(null);
    if (path) navigate(`/${path}`)
  }
  return (
    <div>
      <div className="buttonsetToolbar">
        <Button variant="contained">
          <Link to="/calendar-management-createEvent">
            Create New Availability
          </Link>
        </Button>
        <Button variant="contained">
          <Link to="/calendar-management-event-listing">
            Availability List
          </Link>
        </Button>
        <Button
          href="https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/sync-calendar"
          variant="contained"
        >
          Update Google Account
        </Button>
        <Button variant="contained">
          <Link to="/calendar-management-booked-slots">
            Booked Slot
          </Link>
        </Button>

        {isDev === 1 &&
          <>
            <Button variant="contained">
              <Link to="/events-slot-summery">
                Event Summary
              </Link>
            </Button>

            <Button variant="contained" id="button">
              <Link to="/calendar-management-manageUser">
                Manage User
              </Link>
            </Button>
            <Button variant="contained" onClick={(event) => setAnchorEl(event.currentTarget)}>
              Developer Info
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openCalendarInfo}
              onClose={() => handleCalendarInfoClose()}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleCalendarInfoClose('developer-info/pece-dev')}>Pece Developement</MenuItem>
              <MenuItem onClick={() => handleCalendarInfoClose('developer-info/pece-live')}>Pece Live</MenuItem>
              <MenuItem onClick={() => handleCalendarInfoClose('developer-info/calendar-management')}>Calendar Management</MenuItem>
            </Menu>
          </>
        }
      </div>
      <div className='ContentWrapper'>
        {location.pathname === '/developer-info/pece-live' && <PeceLive />}
        {location.pathname === '/developer-info/pece-dev' && <PeceDev />}
      </div>
    </div>
  )
}

export default DeveloperInfo